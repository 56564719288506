import { convertLengthToPixels, lengthWithUnit } from './AbstractWidget';
import AbstractBoxedWidget from './AbstractBoxedWidget';
import { TextWidget } from './TextWidget';
import { parseColor, STANDARD_PALETTES, STANDARD_PALETTES_KEYS } from './widgetHelpers';
import { hasClass } from '../utils/dom';
import { getMessage, getNumberFormatter, wellKnownNumberFormats } from '../utils/localization';
import { getChartColors } from '../lib/ChartJS';
import { useDebugValue } from 'react';

export default class AbstractChartWidget extends AbstractBoxedWidget {
    _chart = null;

    get chart() {
        return this._chart;
    }

    set chart(chartJsInstance) {
        this._chart = chartJsInstance;
    }

    static getDefaults = () => {
        return {
            ...AbstractBoxedWidget.getDefaults(),
            animated: true,
            backgroundColor: '#ffffff',
            exportable: true,
            chartAlternateText: 'Chart for #FNAMES{, }, #INAMES',
            exportFileName: 'chart.png',
            highlightColor: '#ffd700',
            highlightSelectedFeature: false,
            legendPosition: 'bottom',
            legendFontSize: '14px',
            legendFontColor: '#000',
            legendFontFamily: 'Lato',
            messagePadding: '0px',
            palette: getChartColors(10).join(' '),
            paletteIsFixed: false, // allows for palettes to auto-populate if numnber of colors is < number of values (new in RB2.0)
            titleText: '',
            titlePosition: 'titleBar', // or "BeforeChart" or "TitleBar" (synonym),
            titleFontSize: '14px',
            titleBackground: 'transparent',
            titleAlignment: 'center',
            tooltipFormat: '',
            showLegend: true
        };
    };

    static get plotAreaDefaults() {
        return {
            axisFontFamily: 'Lato',
            axisFontSize: 12,
            axisLabelAllowOffset: false,
            axisLabelAllowResize: false,
            axisLabelAllowWordWrap: true,
            forceOneAxisLabelPerBar: true,
            plotBackgroundColor: '#ffffff',
            plotBorderColor: '#666666',
            plotBorderStyle: 'NotSet',
            plotBorderWidth: '2',
            plotMargins: '5px 5px 5px 5px',
            seriesBindingStyle: 'features-as-series',
            xAxisColor: '#666666',
            xAxisShowBorder: true,
            xAxisShowGrid: false,
            xAxisDateTimeInterval: 'NotSet',
            xAxisGridColor: '#666666',
            xAxisIntervalMode: 'VariableCount',
            xAxisLabelFormat: '#DATE',
            xAxisOriginValue: 'NaN',
            xAxisRange: '',
            xAxisReversed: false,
            xAxisSecondaryPosition: false,
            xAxisSortByDate: 'ascending',
            xAxisTitle: '',
            xAxisUseDate: false,
            xAxisMinLabelRotation: -1,
            xAxisMaxLabelRotation: -1,
            yAxisColor: '#666666',
            yAxisShowBorder: true,
            yAxisShowGrid: false,
            yAxisGridColor: '#666666',
            yAxisIntervalMode: 'VariableCount',
            yAxisLabelFormat: '#,##0.#',
            yAxisOriginValue: 'NaN',
            yAxisRange: '', // '' = 'auto'
            yAxisStepSize: -1,
            yAxisReversed: false,
            yAxisTitle: '',
            yAxisUseMargin: true,
            yAxis2: false,
            yAxis2IntervalMode: 'VariableCount',
            yAxis2LabelFormat: '#,##0.#',
            yAxis2Range: '', // '' = 'auto'
            yAxis2StepSize: -1,
            yAxis2Reversed: false,
            yAxis2Title: ''
        };
    }

    static get statisticsLineDefaults() {
        return {
            showMeanLine: false,
            meanLineLabel: 'Mean',
            showMeanLineOverData: true,
            meanLineWidth: '2px',
            meanLineColor: 'rgb(252, 180, 65)',
            showMedianLine: false,
            medianLineLabel: 'Median',
            showMedianLineOverData: true,
            medianLineWidth: '2px',
            medianLineColor: 'rgb(252, 180, 65)'
        };
    }

    static get annotationLineDefaults() {
        return {
            showTargetLine1: 'none', // "none|horizontal|vertical"
            targetLineLabel1: 'Target #1',
            targetLineValue1: '',
            targetLineWidth1: '2px',
            targetLineStroke1: 'solid', // "solid|dashed|long-dash|dotted|dash-dot",
            targetLineColor1: 'rgb(252, 180, 65)',
            showTargetLine2: 'none', // "none|horizontal|vertical"
            targetLineLabel2: 'Target #2',
            targetLineValue2: '',
            targetLineWidth2: '2px',
            targetLineStroke2: 'solid', // "solid|dashed|long-dash|dotted|dash-dot",
            targetLineColor2: 'rgb(252, 180, 65)',
            showTargetLine3: 'none', // "none|horizontal|vertical"
            targetLineLabel3: 'Target #3',
            targetLineValue3: '',
            targetLineWidth3: '2px',
            targetLineStroke3: 'solid', // "solid|dashed|long-dash|dotted|dash-dot",
            targetLineColor3: 'rgb(252, 180, 65)',
            showTargetLine4: 'none', // "none|horizontal|vertical"
            targetLineLabel4: 'Target #4',
            targetLineValue4: '',
            targetLineWidth4: '2px',
            targetLineStroke4: 'solid', // "solid|dashed|long-dash|dotted|dash-dot",
            targetLineColor4: 'rgb(252, 180, 65)',
            showTargetZone1: 'none', // "none|horizontal|vertical"
            targetZoneLabel1: 'Zone #1',
            targetZoneValues1: '',
            targetZoneColor1: '#ccffcc',
            targetZoneOpacity1: '33%',
            showTargetZone2: 'none', // "none|horizontal|vertical"
            targetZoneLabel2: 'Zone #2',
            targetZoneValues2: '',
            targetZoneColor2: '#ffffcc',
            targetZoneOpacity2: '33%',
            showTargetZone3: 'none', // "none|horizontal|vertical"
            targetZoneLabel3: 'Zone #3',
            targetZoneValues3: '',
            targetZoneColor3: '#ffcccc',
            targetZoneOpacity3: '33%',
            showTargetZone4: 'none', // "none|horizontal|vertical"
            targetZoneLabel4: 'Zone #4',
            targetZoneValues4: '',
            targetZoneColor4: '#cccccc',
            targetZoneOpacity4: '33%',
            targetLinesOverData: true
        };
    }

    static bindAnimationUpdate = (topWidget) => {
        const iconObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    const isAnimated = hasClass(entry.target, 'ia-animated');
                    if (entry.intersectionRatio > 0.75) {
                        if (isAnimated) {
                            const widgetId = entry.target.getAttribute('id'),
                                chart = window[`widgetChart${widgetId}`];
                            console.log('showing'); // DEBUG
                            //chart.update({
                            //    duration: 800
                            //});
                            if (chart !== undefined) chart.update();
                            console.log(chart); // DEBUG
                        }
                        // Gone through once, disconnect (unless we still need the animation handler)
                        if (!isAnimated) observer.disconnect();
                    } else console.log('hiding'); // DEBUG
                });
            },
            {
                root: document.documentElement,
                threshold: 0.75
            }
        );
        iconObserver.observe(topWidget);
    };

    createCanvas = (settings = {}, datasets = [], chartContainerElement = null) => {
        if (chartContainerElement !== null) {
            const doc = chartContainerElement.ownerDocument,
                canvas = doc.createElement('canvas');
            canvas.setAttribute('id', `chart${settings.id}`);
            // Width and height in pixels? If so, might we need to adjust? e.g. for a title bar...
            let w = settings.width,
                h = settings.height;
            if (/^[0-9.]+px$/.test(settings.width) && /^[0-9.]+px$/.test(settings.height)) {
                w = parseFloat(settings.width.replace('px', ''));
                if (chartContainerElement.offsetWidth) w = Math.min(w, chartContainerElement.offsetWidth);
                h = parseFloat(settings.height.replace('px', ''));
                if (chartContainerElement.offsetHeight) h = Math.min(h, chartContainerElement.offsetHeight);
                canvas.setAttribute('width', w.toFixed(0));
                canvas.setAttribute('height', h.toFixed(0));
                canvas.setAttribute(
                    'style',
                    `width: ${w.toFixed(0)}px; height: ${h.toFixed(0)}px; max-width: 100%; max-height: 100%;`
                );
            } else {
                if (w === 'auto') w = '100%';
                if (h === 'auto') h = '100%; min-height: 200px';
                canvas.setAttribute('style', `width: ${w}; height: ${h}; max-width: 100%; max-height: 100%;`);
            }
            canvas.setAttribute('role', 'img');
            if (settings.chartAlternateText !== undefined) {
                const alt = TextWidget.insertValuesIntoText(
                    settings.chartAlternateText,
                    datasets,
                    undefined,
                    settings.locale,
                    settings.noDataText,
                    false
                );
                if (chartContainerElement.tagName === 'FIGURE') chartContainerElement.setAttribute('aria-label', alt);
                canvas.setAttribute('aria-label', alt);
            }
            if (
                settings.backgroundColor !== undefined &&
                settings.backgroundColor !== null &&
                settings.backgroundColor !== ''
            ) {
                canvas.style.backgroundColor = parseColor(settings.backgroundColor);
                canvas.getContext('2d').fillStyle = parseColor(settings.backgroundColor);
                canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height);
                canvas.setAttribute('data-bg-color', settings.backgroundColor); // Allows this to pass through to chart engine
            } else canvas.setAttribute('data-bg-color', '#ffffff');
            canvas.setAttribute('id', `chart${settings.id}`);
            chartContainerElement.appendChild(canvas);
            chartContainerElement.style.backgroundColor = canvas.getAttribute('data-bg-color');
            return canvas;
        }
        return null;
    };

    // Convert a set of RB 2.0 settings to their ChartJS equivalents
    getChartOptions = (settings) => {
        const {
                plotMargins,
                plotBackgroundColor,
                showLegend,
                legendFontFamily,
                legendFontSize,
                legendFontColor = '#000',
                legendPosition = 'bottom',
                legendReverseOrder = false,
                legendWeight = 20,
                width,
                height
            } = settings,
            opts = {
                plugins: {
                    legend: {
                        display: showLegend !== undefined ? showLegend : false
                    }
                }
            },
            mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        if (mediaQuery.matches) {
            opts.animation = false;
        }
        let tokens;
        if (plotMargins !== undefined && plotMargins !== null) {
            tokens = plotMargins.split(' ').map((t) => parseFloat(t));
            if (opts.layout === undefined) opts.layout = {};
            opts.layout.padding = {
                top: tokens[0],
                right: tokens.length > 1 ? tokens[1] : tokens[0],
                bottom: tokens.length > 2 ? tokens[2] : tokens[0],
                left: tokens.length > 3 ? tokens[3] : tokens.length > 1 ? tokens[1] : tokens[0]
            };
        }
        // Put some other ones in here too - even tho' they might get pulled apart later before the call into chartjs...
        if (showLegend && (legendFontFamily !== undefined || legendFontSize !== undefined)) {
            if (opts.plugins === undefined) opts.plugins = {};
            opts.plugins.legend = {
                position: legendPosition.toLowerCase(),
                labels: {
                    font: { family: legendFontFamily, size: parseFloat(legendFontSize) },
                    color: parseColor(legendFontColor)
                },
                reverse: legendReverseOrder
            };
            const legPos = legendPosition.toLowerCase(),
                hzFlex = legPos === 'left' || legPos === 'right';
            if (hzFlex) {
                const lmw = legendWeight * 0.01 * parseFloat(lengthWithUnit(width).replace('px', ''));
                opts.plugins.legend.maxWidth = lmw;
            } else {
                const lmh = legendWeight * 0.01 * parseFloat(lengthWithUnit(height).replace('px', ''));
                opts.plugins.legend.maxHeight = lmh;
            }
        }
        if (plotBackgroundColor !== undefined && plotBackgroundColor !== null) {
            if (opts.chartArea === undefined) opts.chartArea = {};
            opts.chartArea.backgroundColor = parseColor(plotBackgroundColor);
        }
        opts.plugins.tooltip = this.getTooltipOptions(settings);
        return opts;
    };

    getTooltipOptions = (settings, tooltipMode = 'point') => {
        const { tooltipFormat, dataPointLabelFormat, locale = 'en', numberFormat = '#.#', noDataText = '' } = settings,
            opts = {
                mode: tooltipMode
            },
            ttf = tooltipFormat; //((dataPointLabelFormat !== undefined) && (dataPointLabelFormat !== '') ? dataPointLabelFormat : tooltipFormat);
        if (settings.axisFontFamily !== undefined && settings.axisFontFamily !== null) {
            opts.titleFont =
                opts.bodyFont =
                opts.footerFont =
                    {
                        family: settings.axisFontFamily
                    };
        }
        if (ttf !== undefined && ttf !== null) {
            const isPerc = ttf.indexOf('#PERCENT') >= 0,
                //fmtPattern = (ttf.indexOf('{') >= 0 ? ttf.substring(ttf.indexOf('{') + 1, ttf.indexOf('}')) : (numberFormat !== '' ? numberFormat : '#.#')),
                //customFmt = getNumberFormatter(locale, fmtPattern);
                customFmt = buildFormattersFromLabel(ttf, locale, getNumberFormatter(locale, numberFormat));

            opts.callbacks = {
                title: (tooltipItem) => {
                    const tti = Array.isArray(tooltipItem) ? tooltipItem : [tooltipItem],
                        series = tti.map((tt) => tt.dataset.label || '').join(', ');
                    return series;
                },
                label: (tooltipItem) => {
                    const labels =
                            tooltipItem.dataset.labels !== undefined
                                ? tooltipItem.dataset.labels
                                : tooltipItem.dataset.sourceLabels !== undefined
                                ? tooltipItem.dataset.sourceLabels
                                : tooltipItem.chart !== undefined &&
                                  tooltipItem.chart.config !== undefined &&
                                  tooltipItem.chart.config.data !== undefined
                                ? tooltipItem.chart.config.data.labels
                                : undefined,
                        sourceLabels = tooltipItem.dataset.sourceLabels,
                        activeLabel =
                            labels !== undefined && labels !== null && Array.isArray(labels)
                                ? Array.isArray(labels[tooltipItem.dataIndex])
                                    ? labels[tooltipItem.dataIndex].join(' ')
                                    : labels[tooltipItem.dataIndex] === '[tick]' && sourceLabels !== undefined
                                    ? sourceLabels[tooltipItem.dataIndex]
                                    : labels[tooltipItem.dataIndex]
                                : '', // Not the X value, but the true label
                        trueXLabel =
                            activeLabel !== '' && activeLabel !== '[tick]'
                                ? activeLabel
                                : typeof tooltipItem.label === 'string' && tooltipItem.label !== ''
                                ? tooltipItem.label
                                : activeLabel,
                        hasDataVals =
                            tooltipItem.dataset !== undefined &&
                            tooltipItem.dataset !== null &&
                            tooltipItem.dataset.data !== undefined &&
                            tooltipItem.dataset.data !== null,
                        trueDataVals = hasDataVals ? tooltipItem.dataset.data[tooltipItem.dataIndex] : null,
                        ttv = hasDataVals ? tooltipItem.dataset.data[tooltipItem.dataIndex] : Number.NaN;
                    let perc = 0;
                    if (isPerc && !isNaN(ttv)) {
                        let sum = 0;
                        for (let i of tooltipItem.dataset.data) sum += i != null ? (i.y !== undefined ? i.y : i) : 0;
                        perc = (ttv * 100.0) / sum;
                        perc = `${(customFmt['PERCENT'] || customFmt._def).format(perc)}%`;
                    }
                    const basicLabel =
                        /*labels !== undefined &&
                        labels !== null &&
                        Array.isArray(labels) &&
                        labels.length > tooltipItem.dataIndex */
                        activeLabel !== undefined
                            ? ttf !== ''
                                ? ttf
                                      .replace(/#NAME|#FNAME/g, activeLabel)
                                      .replace(
                                          /#VALZ|#ZVAL|#SIZE/g,
                                          trueDataVals.v !== undefined && !isNaN(trueDataVals.v)
                                              ? (customFmt['VALZ'] || customFmt['ZVAL'] || customFmt._def).format(
                                                    trueDataVals.v
                                                )
                                              : noDataText
                                      )
                                      .replace(
                                          /#VALX|XVAL/gi,
                                          trueDataVals.x !== undefined && !isNaN(trueDataVals.x)
                                              ? (customFmt['VALX'] || customFmt['XVAL'] || customFmt._def).format(
                                                    trueDataVals.x
                                                )
                                              : trueXLabel
                                      )
                                      .replace(/#VALX|#XVAL|\[tick\]/g, trueXLabel)
                                      .replace(
                                          /#VALY|#YVAL/g,
                                          !isNaN(ttv.y !== undefined ? ttv.y : ttv)
                                              ? (customFmt['VALY'] || customFmt['YVAL'] || customFmt._def).format(
                                                    ttv.y !== undefined ? ttv.y : ttv
                                                )
                                              : tooltipItem.formattedValue
                                      )
                                      .replace(
                                          /#MINY|#YMIN|#LL/g,
                                          trueDataVals.yMin !== undefined && !isNaN(trueDataVals.yMin)
                                              ? (customFmt['MINY'] || customFmt['YMIN'] || customFmt._def).format(
                                                    trueDataVals.yMin
                                                )
                                              : noDataText
                                      )
                                      .replace(
                                          /#MAXY|#YMAX|#UL/g,
                                          trueDataVals.yMax !== undefined && !isNaN(trueDataVals.yMax)
                                              ? (customFmt['MAXY'] || customFmt['YMAX'] || customFmt._def).format(
                                                    trueDataVals.yMax
                                                )
                                              : noDataText
                                      )
                                      .replace(/#SERIES|#SER|#LEGENDTEXT/g, tooltipItem.dataset.label || '') // Already handled by the title (above)?
                                      .replace(/#PERCENT(\{.*\})?/g, perc)
                                      .replace('%%', '%') // Deal with inconsistent user ability
                                      //.replace(`{${fmtPattern}}`, '')
                                      .replace(/#COLOR/g, '')
                                : `${trueXLabel}: ${
                                      !isNaN(ttv) ? customFmt._def.format(ttv) : tooltipItem.formattedValue
                                  }`
                            : '';
                    const labelLines = basicLabel
                        .replace(/\\n/g, '\n')
                        .replace(/<br>|<br\s*\/>/g, '\n')
                        .split('\n');
                    return labelLines.map((ln) => ln.replace(/\{[0#,.A-Z]+\}/g, ''));
                }
            };
        }
        return opts;
    };

    applyAxesSettings = (settings = {}, chartConfig = {}, axesAreFlipped = false, forceAxes = []) => {
        const { options: chartOptions, data: chartData } = chartConfig,
            {
                xAxisLabelFormat = '#DATE',
                forceOneAxisLabelPerBar = true,
                xAxisReversed,
                xAxesOffset,
                xAxesCallback,
                xAxisColor,
                xAxisGridColor,
                xAxisShowBorder = true,
                xAxisShowGrid = false,
                xAxisSecondaryPosition = false,
                xAxisUseDate = false,
                xAxisUseMargins,
                xAxisMinLabelRotation = -1,
                xAxisMaxLabelRotation = -1,
                xAxisRange,
                yAxisLabelFormat = '#,##0.#',
                yAxisRange = '', // '' = 'auto'
                yAxisStepSize = -1,
                yAxisReversed,
                yAxisAnchor = 'left',
                yAxis2 = false,
                yAxis2Range = '', // '' = 'auto'
                yAxis2StepSize = -1,
                yAxis2Reversed,
                yAxis2Anchor = 'right',
                yAxesOffset,
                yAxesCallback,
                yAxisColor,
                yAxisGridColor,
                yAxisShowBorder = true,
                yAxisShowGrid = false,
                axisTickLength = 5,
                axisTickPadding = 3,
                locale = 'en'
            } = settings;
        const xAxes = [chartOptions.scales.x];
        const yAxes = [chartOptions.scales.y, chartOptions.scales.left, chartOptions.scales.right];
        const axes =
            forceAxes !== undefined && forceAxes.length > 0
                ? forceAxes
                : axesAreFlipped
                ? [yAxes, xAxes]
                : [xAxes, yAxes];
        if (!Array.isArray(axes[0])) axes[0] = [axes[0]];
        if (!Array.isArray(axes[1])) axes[1] = [axes[1]];
        for (let axy of [...axes[0], ...axes[1]]) {
            if (axy.grid !== undefined) axy.grid.tickMarkLength = axisTickLength;
            if (axy.ticks !== undefined) axy.ticks.padding = axisTickPadding;
        }
        // Draw borders by default... waiting for chartjs 3.0

        // Standard Y axis
        if (yAxisLabelFormat !== undefined) {
            for (let ya of axes[1]) {
                ya.ticks.display = yAxisLabelFormat.replace(/^\[blank\]$/, '') !== '';
                const nf = getNumberFormatter(locale, yAxisLabelFormat);
                ya.ticks.callback = (value, index, values) => {
                    return wellKnownNumberFormats.indexOf(yAxisLabelFormat) >= 0
                        ? nf.format(value)
                        : yAxisLabelFormat.replace(nf.pattern, nf.format(value));
                };
            }
        }
        if (yAxisRange !== undefined && yAxisRange.toLowerCase() !== '' && yAxisRange.toLowerCase() !== 'auto') {
            const [min, max] = yAxisRange.split(','),
                vMin = min !== undefined ? parseFloat(min.replace(/[^0-9.]/g, '')) : NaN,
                flexMin = min !== undefined && min.substring(0, 1) === '~',
                vMax = max !== undefined ? parseFloat(max.replace(/[^0-9.]/g, '')) : NaN,
                flexMax = max !== undefined && max.substring(0, 1) === '~';
            if (!isNaN(vMin)) {
                for (let ya of axes[1]) {
                    if (flexMin) ya.suggestedMin = vMin;
                    else ya.min = vMin;
                }
            }
            if (!isNaN(vMax)) {
                for (let ya of axes[1]) {
                    if (flexMax) ya.suggestedMax = vMax;
                    else ya.max = vMax;
                }
            }
        }
        if (yAxisStepSize !== undefined && yAxisStepSize > 0) {
            for (let ya of axes[1]) {
                ya.ticks.stepSize = yAxisStepSize;
            }
        }
        if (yAxisReversed !== undefined) {
            for (let ya of axes[1]) {
                ya.reverse = yAxisReversed;
            }
            if (yAxisReversed === true) {
                for (let xa of axes[0]) {
                    xa.position = 'right';
                }
            }
        }
        if (yAxisAnchor !== undefined) {
            for (let ya of axes[0]) {
                ya.position = yAxisAnchor;
            }
        }
        // Secondary Y axis
        if (yAxis2) {
            let ya = axes[1][1];
            if (ya === undefined) {
                axes[1].push(JSON.parse(JSON.stringify(axes[1][0])));
                ya = axes[1][1];
            }
            ya.display = true;
            ya.position = yAxis2Anchor; // Default
            if (yAxis2Range !== undefined && yAxis2Range.toLowerCase() !== '' && yAxis2Range.toLowerCase() !== 'auto') {
                const [min, max] = yAxis2Range.split(','),
                    vMin = min !== undefined ? parseFloat(min.replace(/[^0-9.]/g, '')) : NaN,
                    flexMin = min !== undefined && min.substring(0, 1) === '~',
                    vMax = max !== undefined ? parseFloat(max.replace(/[^0-9\.]/g, '')) : NaN,
                    flexMax = max !== undefined && max.substring(0, 1) === '~';
                if (!isNaN(vMin) && !isNaN(vMax) && vMin !== vMax) {
                    if (flexMin) ya.suggestedMin = vMin;
                    else ya.min = vMin;
                    if (flexMax) ya.suggestedMax = vMax;
                    else ya.max = vMax;
                }
            }
            if (yAxis2StepSize !== undefined && yAxis2StepSize > 0) ya.ticks.stepSize = yAxis2StepSize;
            if (yAxis2Reversed !== undefined) ya.reverse = yAxis2Reversed;
        }
        // X axis
        if (xAxisUseDate === true) {
            for (let xa of axes[0]) {
                xa.type = 'time';
            }
        }
        if (xAxisUseMargins !== undefined) {
            for (let xa of axes[0]) {
                xa.offset = xAxisUseMargins;
            }
        }
        if (xAxisLabelFormat !== undefined) {
            for (let xa of axes[0]) {
                const labelSet =
                    chartData.labels !== undefined
                        ? chartData.labels
                        : chartData.datasets !== undefined &&
                          chartData.datasets.length > 0 &&
                          chartData.datasets[0].labels !== undefined
                        ? chartData.datasets[0].labels
                        : [];
                xa.labels = labelSet.map((label, labelIndex) => {
                    const wrappable = Array.isArray(label),
                        lbl = wrappable ? label.join('\t') : label,
                        replacement = xAxisLabelFormat
                            .replace(/#IDATE/g, lbl)
                            .replace(/#DATE/g, lbl)
                            .replace(/#NAME/g, lbl)
                            .replace(/#INAME/g, lbl)
                            .replace(/^\[blank\]$/, '')
                            .replace(/^\[tick\]$/, '')
                            .replace(`${lbl} ${lbl}`, lbl)
                            .replace(`${lbl}, ${lbl}`, lbl)
                            .replace(`${lbl},${lbl}`, lbl);
                    //return replacement;
                    return wrappable ? replacement.split('\t') : replacement;
                });
                // If it is flipped/horizontal then take out the callback because we want the label not the formatted label
                if (xa.ticks !== undefined && xa.ticks.callback !== undefined) delete xa.ticks.callback;
                if (xa.ticks !== undefined) xa.ticks.display = xAxisLabelFormat.replace(/^\[blank\]$/, '') !== '';
                if (xa.grid !== undefined) xa.grid.drawTicks = xAxisLabelFormat.replace(/^\[blank\]$/, '') !== '';
            }
        }
        if (forceOneAxisLabelPerBar) {
            for (let xa of axes[0]) {
                xa.ticks.autoSkip = false;
            }
        }
        if (xAxisReversed !== undefined) {
            for (let xa of axes[0]) {
                xa.reverse = xAxisReversed;
            }
        }
        if (xAxisMinLabelRotation !== -1) {
            for (let xa of axes[0]) xa.ticks.minRotation = xAxisMinLabelRotation;
        }
        if (xAxisMaxLabelRotation !== -1) {
            for (let xa of axes[0]) xa.ticks.maxRotation = xAxisMaxLabelRotation;
        }
        if (xAxesOffset !== undefined) {
            for (let xa of axes[0]) {
                xa.offset = xAxesOffset;
            }
        }
        if (xAxisSecondaryPosition) {
            for (let xa of axes[0]) {
                xa.position = xa.position === 'right' ? 'left' : xa.position === 'top' ? 'bottom' : 'top';
                //console.log(xa); // DEBUG
            }
        }
        // X Axis
        if (xAxisColor !== undefined) {
            for (let xa of axes[0]) {
                xa.grid.zeroLineColor = parseColor(yAxisColor);
                xa.grid.drawOnChartArea = xAxisShowGrid && xAxisGridColor !== undefined;
                xa.grid.drawBorder = xAxisShowBorder;
                xa.grid.display = true; // Display by default...
                xa.grid.lineWidth = xAxisGridColor !== undefined ? 1 : 0; // But hidden by default
                xa.grid.color = xa.grid.drawOnChartArea
                    ? xAxisGridColor !== undefined
                        ? parseColor(xAxisGridColor)
                        : undefined
                    : parseColor(xAxisColor);
                xa.grid.borderColor = parseColor(xAxisColor);
            }
        }
        if (xAxisRange !== undefined && xAxisRange.toLowerCase() !== '' && xAxisRange.toLowerCase() !== 'auto') {
            const [min, max] = xAxisRange.split(','),
                vMin = min !== undefined ? parseFloat(min.replace(/[^0-9.]/g, '')) : NaN,
                flexMin = min !== undefined && min.substring(0, 1) === '~',
                vMax = max !== undefined ? parseFloat(max.replace(/[^0-9.]/g, '')) : NaN,
                flexMax = max !== undefined && max.substring(0, 1) === '~';
            if (!isNaN(vMin) && !isNaN(vMax) && vMin !== vMax) {
                for (let xa of axes[0]) {
                    if (flexMin) xa.suggestedMin = vMin;
                    else xa.min = vMin;
                    if (flexMax) xa.suggestedMax = vMax;
                    else xa.max = vMax;
                }
            }
        }
        if (yAxesOffset !== undefined) {
            for (let ya of axes[1]) {
                ya.offset = yAxesOffset;
            }
        }
        // Y axis
        if (yAxisColor !== undefined) {
            for (let ya of axes[1]) {
                ya.grid.zeroLineColor = parseColor(xAxisColor);
                ya.grid.drawOnChartArea = yAxisShowGrid && yAxisGridColor !== undefined;
                ya.grid.drawBorder = yAxisShowBorder;
                ya.grid.display = true; // Display by default...
                ya.grid.lineWidth = yAxisGridColor !== undefined ? 1 : 0; // But hidden by default
                ya.grid.color = ya.grid.drawOnChartArea
                    ? yAxisGridColor !== undefined
                        ? parseColor(yAxisGridColor)
                        : undefined
                    : parseColor(yAxisColor);
                ya.grid.borderColor = parseColor(yAxisColor);
            }
        }
        if (xAxesCallback !== undefined) {
            for (let xa of axes[0]) {
                xa.afterSetDimensions = (axes) => {
                    xAxesCallback(axes, 'afterSetDimensions');
                };
            }
        }
        if (yAxesCallback !== undefined) {
            for (let ya of axes[1]) {
                ya.afterSetDimensions = (axes) => {
                    yAxesCallback(axes, 'afterSetDimensions');
                };
            }
        }
    };

    adjustAxisLabels = (
        settings = {},
        chartConfig = {},
        canvasContext2d = null,
        targetWidth = -1,
        perLabelMargin = 6
    ) => {
        let tokens,
            half,
            adjusted = false,
            fit = true;
        const padding =
                chartConfig.options !== undefined &&
                chartConfig.options.layout !== undefined &&
                chartConfig.options.layout.padding !== undefined
                    ? chartConfig.options.layout.padding
                    : { left: 5, right: 5 },
            possibleWidth =
                canvasContext2d !== null && canvasContext2d.canvas !== undefined
                    ? canvasContext2d.canvas.width
                    : convertLengthToPixels(settings.width, true, true, 0),
            hzSpace =
                (targetWidth > 0
                    ? targetWidth
                    : (possibleWidth - padding.left - padding.right - 10) / chartConfig.data.labels.length) -
                perLabelMargin; // 10 = fuzziness of full, perLabelMargin (6) = fuzziness of each label
        for (let i = 0; i < chartConfig.data.labels.length; i++) {
            //if (settings.xAxisLabelFormat !== undefined) chartConfig.data.labels[i] = settings.xAxisLabelFormat.replace(/#NAME/g, chartConfig.data.labels[i]).replace(/^\[blank\]$/, '');
            if (settings.axisLabelAllowWordWrap === true) {
                const isLabelArray = Array.isArray(chartConfig.data.labels[i]),
                    labelText = isLabelArray ? chartConfig.data.labels[i].join(' ') : chartConfig.data.labels[i];
                tokens = labelText.length > 10 ? labelText.split(' ') : [];
                if (canvasContext2d !== null) {
                    const best = getBestTextForWidth(
                        canvasContext2d,
                        labelText,
                        hzSpace,
                        settings.axisFontFamily,
                        settings.axisFontSize
                    );
                    adjusted =
                        adjusted ||
                        (isLabelArray
                            ? best.lines.length !== chartConfig.data.labels[i].length
                            : best.lines.length > 1);
                    fit = fit && best.fit;
                    chartConfig.data.labels[i] = best.lines;
                } else if (tokens.length > 1) {
                    half = Math.ceil(tokens.length / 2.0);
                    adjusted = adjusted || (isLabelArray ? chartConfig.data.labels[i].length !== 2 : true);
                    chartConfig.data.labels[i] =
                        settings.barOrientation.toLowerCase() === 'horizontal'
                            ? [tokens.slice(0, half).join(' '), tokens.slice(half).join(' ')]
                            : tokens;
                }
            }
        }
        return {
            adjusted,
            fit
        };
    };

    applyStatisticsLines = (settings = {}, chartConfig = {}, extraLines = {}) => {
        let adjustedExtras = {
            ...extraLines
        };
        if (settings.showMeanLine === true) {
            const numf = getNumberFormatter(settings.locale, settings.numberFormat),
                vals = chartConfig.data.datasets[0].data,
                mean = vals.reduce((a, b) => a + b, 0) / vals.length;
            adjustedExtras = {
                ...adjustedExtras,
                showTargetLineMean: 'y-axis',
                targetLineLabelMean: (settings.meanLineLabel || '')
                    .replace(/#VALUE/gi, numf.format(mean))
                    .replace(/#VAL/gi, numf.format(mean)),
                targetLineValueMean: mean,
                targetLineColorMean: settings.meanLineColor,
                targetLineWidthMean: settings.meanLineWidth,
                targetLineStrokeMean: settings.meanLineStroke,
                targetLineOverDataMean: settings.showMeanLineOverData
            };
        }
        if (settings.showMedianLine === true) {
            const vals = [...chartConfig.data.datasets[0].data];
            vals.sort();
            const numf = getNumberFormatter(settings.locale, settings.numberFormat),
                median = vals[Math.floor(vals.length / 2.0)];
            adjustedExtras = {
                ...adjustedExtras,
                showTargetLineMedian: 'y-axis',
                targetLineLabelMedian: (settings.medianLineLabel || '')
                    .replace(/#VALUE/gi, numf.format(median))
                    .replace(/#VAL/gi, numf.format(median)),
                targetLineValueMedian: median,
                targetLineColorMedian: settings.medianLineColor,
                targetLineWidthMedian: settings.medianLineWidth,
                targetLineStrokeMedian: settings.medianLineStroke,
                targetLineOverDataMedian: settings.showMedianLineOverData
            };
        }
        return adjustedExtras;
    };

    applyLineAnnotations = (settings = {}, chartConfig = {}, axesAreFlipped = false, numberFormatter) => {
        const lineAnnotationSettings = {
                ...AbstractChartWidget.annotationLineDefaults,
                ...settings
            },
            {
                axisFontFamily = 'sans-serif',
                axisFontSize = '12px',
                xAxisColor = '#333',
                yAxisColor = '#333',
                targetLinesOverData = true,
                targetLineLabelBackgroundColor = 'rgba(255, 255, 255, 0)',
                targetLineLabelColor = '#fff',
                targetZoneLabelColor = ''
            } = settings,
            numf =
                numberFormatter !== undefined
                    ? numberFormatter
                    : getNumberFormatter(settings.locale, settings.numberFormat),
            annotationSet = {},
            keyNames = Object.keys(lineAnnotationSettings).filter((k) => /^(showTargetLine|showTargetZone).*$/.test(k));
        for (let key of keyNames) {
            const keyIndex = key.substring('showTargetLine'.length); // 'showTargetLine'.length === 'showTargetZone'.length
            if (
                lineAnnotationSettings[`showTargetLine${keyIndex}`] !== undefined &&
                (lineAnnotationSettings[`showTargetLine${keyIndex}`] === 'x-axis' ||
                    lineAnnotationSettings[`showTargetLine${keyIndex}`] === 'y-axis')
            ) {
                const annotationLineWidth =
                        lineAnnotationSettings[`targetLineWidth${keyIndex}`] !== undefined
                            ? convertLengthToPixels(lineAnnotationSettings[`targetLineWidth${keyIndex}`], true, true, 2)
                            : 2,
                    annotationLineValue = lineAnnotationSettings[`targetLineValue${keyIndex}`],
                    annotationLineAxis = lineAnnotationSettings[`showTargetLine${keyIndex}`],
                    targetLineOverData = lineAnnotationSettings[`targetLineOverData${keyIndex}`],
                    annotationLineOverData =
                        targetLineOverData !== undefined ? targetLineOverData === true : targetLinesOverData,
                    annotation = {
                        type: 'line',
                        borderColor: parseColor(lineAnnotationSettings[`targetLineColor${keyIndex}`]),
                        borderDash: createDashedLine(
                            lineAnnotationSettings[`targetLineStroke${keyIndex}`] || 'solid',
                            annotationLineWidth
                        ),
                        borderDashOffset: 0,
                        borderWidth: annotationLineWidth,
                        label: {
                            display:
                                lineAnnotationSettings[`targetLineLabel${keyIndex}`] !== undefined &&
                                lineAnnotationSettings[`targetLineLabel${keyIndex}`] !== '',
                            content: (ctx) => {
                                return lineAnnotationSettings[`targetLineLabel${keyIndex}`];
                            },
                            position: 'end',
                            font: {
                                family: axisFontFamily,
                                weight: 'normal',
                                size: convertLengthToPixels(axisFontSize, true, true)
                            },
                            backgroundColor: parseColor(lineAnnotationSettings[`targetLineColor${keyIndex}`]), //parseColor(targetLineLabelBackgroundColor),
                            color: parseColor(
                                targetLineLabelColor !== ''
                                    ? targetLineLabelColor
                                    : annotationLineAxis === 'x-axis'
                                    ? xAxisColor
                                    : yAxisColor
                            ),
                            padding: {
                                x: 5,
                                y: 2
                            },
                            rotation:
                                lineAnnotationSettings[`targetLineLabelRotation${keyIndex}`] !== undefined &&
                                lineAnnotationSettings[`targetLineLabelRotation${keyIndex}`] !== ''
                                    ? parseFloat(lineAnnotationSettings[`targetLineLabelRotation${keyIndex}`])
                                    : annotationLineAxis === 'x-axis'
                                    ? 90
                                    : 0
                        },
                        scaleID: annotationLineAxis === 'x-axis' ? 'x' : 'y',
                        value: (ctx) => {
                            return annotationLineAxis === 'x-axis' &&
                                ctx.chart !== undefined &&
                                ctx.chart.config !== undefined &&
                                ctx.chart.config.data !== undefined &&
                                ctx.chart.config.data.labels !== undefined
                                ? ctx.chart.config.data.labels.findIndex(
                                      (lbl) => lbl.toString() === annotationLineValue
                                  ) // could be string or could be string[]
                                : parseFloat(annotationLineValue);
                        },
                        drawTime: annotationLineOverData ? 'afterDatasetsDraw' : 'beforeDatasetsDraw'
                    };
                if (axesAreFlipped) annotation.scaleID = annotation.scaleID === 'x' ? 'y' : 'x';
                annotationSet[`annotationLine${keyIndex}`] = annotation;
            }
            if (
                lineAnnotationSettings[`showTargetZone${keyIndex}`] !== undefined &&
                lineAnnotationSettings[`showTargetZone${keyIndex}`] !== 'none'
            ) {
                const annotationValues = lineAnnotationSettings[`targetZoneValues${keyIndex}`].split(','),
                    annotationZoneAxis = lineAnnotationSettings[`showTargetZone${keyIndex}`],
                    annotation = {
                        type: 'box',
                        backgroundColor: parseColor(
                            lineAnnotationSettings[`targetZoneColor${keyIndex}`],
                            lineAnnotationSettings[`targetZoneOpacity${keyIndex}`]
                        ),
                        borderWidth: 0,
                        label: {
                            display:
                                lineAnnotationSettings[`targetZoneLabel${keyIndex}`] !== undefined &&
                                lineAnnotationSettings[`targetZoneLabel${keyIndex}`] !== '',
                            content: (ctx) => {
                                return lineAnnotationSettings[`targetZoneLabel${keyIndex}`];
                            },
                            font: {
                                family: axisFontFamily,
                                weight: 'normal',
                                size: convertLengthToPixels(axisFontSize, true, true)
                            },
                            color: parseColor(
                                targetZoneLabelColor !== ''
                                    ? targetZoneLabelColor
                                    : annotationZoneAxis === 'x-axis'
                                    ? xAxisColor
                                    : yAxisColor
                            )
                        },
                        scaleID: annotationZoneAxis === 'x-axis' ? 'x' : 'y',
                        drawTime: 'beforeDatasetsDraw'
                    };
                if (axesAreFlipped) annotation.scaleID = annotation.scaleID === 'x' ? 'y' : 'x';
                if (annotationZoneAxis === 'x-axis') {
                    const fm = (ctx) => {
                        return ctx.chart !== undefined &&
                            ctx.chart.config !== undefined &&
                            ctx.chart.config.data !== undefined &&
                            ctx.chart.config.data.labels !== undefined
                            ? ctx.chart.config.data.labels.findIndex((lbl) => lbl.toString() === annotationValues[0]) // could be string or could be string[]
                            : parseFloat(annotationValues[0]);
                    };
                    if (axesAreFlipped) annotation.yMin = fm;
                    else annotation.xMin = fm;
                    const fx = (ctx) => {
                        return ctx.chart !== undefined &&
                            ctx.chart.config !== undefined &&
                            ctx.chart.config.data !== undefined &&
                            ctx.chart.config.data.labels !== undefined
                            ? ctx.chart.config.data.labels.findIndex((lbl) => lbl.toString() === annotationValues[1]) // could be string or could be string[]
                            : parseFloat(annotationValues[1]);
                    };
                    if (axesAreFlipped) annotation.yMax = fx;
                    else annotation.xMax = fx;
                    annotation.label.position = {
                        x: 'center',
                        y: 'start'
                    };
                } else {
                    if (axesAreFlipped) annotation.xMin = parseFloat(annotationValues[0]);
                    else annotation.yMin = parseFloat(annotationValues[0]);
                    if (axesAreFlipped) annotation.xMax = parseFloat(annotationValues[1]);
                    else annotation.yMax = parseFloat(annotationValues[1]);
                    annotation.label.position = {
                        x: 'end',
                        y: 'start'
                    };
                }
                annotationSet[`annotationBox${keyIndex}`] = annotation;
            }
        }
        if (chartConfig.options === undefined) chartConfig.options = {};
        if (chartConfig.options.plugins === undefined) chartConfig.options.plugins = {};
        if (chartConfig.options.plugins.annotation === undefined) chartConfig.options.plugins.annotation = {};
        chartConfig.options.plugins.annotation.annotations = {
            ...chartConfig.options.plugins.annotation.annotations,
            ...annotationSet
        };
    };

    onResponsiveEvent = (chart, size, settings) => {
        if (this._resizeTimeoutId !== undefined) clearTimeout(this._resizeTimeoutId);
        this._resizeTimeoutId = setTimeout(() => {
            const usefulWidth = chart.width > 0,
                widthFactor = chart.width / convertLengthToPixels(settings.width, false, true, 500),
                targetWidthFactor = settings.allowLabelSkipAt !== undefined ? settings.allowLabelSkipAt : 0.75,
                availableWidth =
                    chart.chartArea !== undefined ? chart.chartArea.right - chart.chartArea.left : chart.width,
                { adjusted: adjustedForSize, fit } = this.adjustAxisLabels(
                    settings,
                    chart,
                    chart.ctx,
                    availableWidth / chart.config.data.labels.length
                ),
                hasX = chart.options.scales.x !== undefined,
                smallAndAdjustable = hasX && widthFactor < targetWidthFactor;
            if (usefulWidth) {
                if (adjustedForSize) {
                    // Forcibly update the labels because the chart doesn't notice (no new data?)
                    if (hasX) chart.options.scales.x.labels = [...chart.data.labels];
                    // Allow emergency rotation? And maybe skip?
                    if (hasX && !fit) {
                        chart.options.scales.x.ticks.maxRotation = 90;
                        chart.options.scales.x.ticks.autoSkip = true; // Always allow skip if text doesn't fit
                    }
                } else if (smallAndAdjustable) {
                    chart.options.scales.x.ticks.autoSkip = true;
                } // Always allow skip if very small
                else if (hasX) chart.options.scales.x.ticks.autoSkip = settings.forceOneAxisLabelPerBar !== true;
                if (adjustedForSize || smallAndAdjustable) chart.update();
            }
        }, 250);
    };

    forceAxesRender = (chart, settings) => {
        for (let axisId of Object.keys(chart.scales)) {
            if (axisId === 'x-axis-0') {
                const { ctx, left, right, top, _gridLineItems: tickMarks, options } = chart.scales[axisId],
                    { grid } = options;
                ctx.strokeStyle = parseColor(settings.xAxisColor);
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(left, top);
                ctx.lineTo(right, top);
                ctx.stroke();
                if (
                    false &&
                    settings.xAxisLabelFormat !== undefined &&
                    settings.xAxisLabelFormat !== null &&
                    settings.xAxisLabelFormat !== ''
                ) {
                    ctx.strokeStyle =
                        settings.xAxisTickMarkColor !== undefined ? settings.xAxisTickMarkColor : settings.xAxisColor;
                    if (tickMarks !== undefined && tickMarks !== null) {
                        // Ticks... default is to use the grid line color but we really want the axis color
                        for (let axisTick of tickMarks) {
                            const { x1 } = axisTick;
                            ctx.beginPath();
                            ctx.moveTo(x1, top);
                            ctx.lineTo(x1, top + (grid.tickMarkLength || 5));
                            ctx.stroke();
                        }
                    }
                }
            } else if (axisId === 'y-axis-0') {
                const { ctx, right, top, bottom, _gridLineItems: tickMarks, options } = chart.scales[axisId],
                    { grid } = options;
                ctx.strokeStyle = parseColor(settings.yAxisColor);
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(right, top);
                ctx.lineTo(right, bottom);
                ctx.stroke();
                if (
                    false &&
                    settings.yAxisLabelFormat !== undefined &&
                    settings.yAxisLabelFormat !== null &&
                    settings.yAxisLabelFormat !== ''
                ) {
                    ctx.strokeStyle =
                        settings.yAxisTickMarkColor !== undefined ? settings.yAxisTickMarkColor : settings.yAxisColor;
                    if (tickMarks !== undefined && tickMarks !== null) {
                        // Ticks... default is to use the grid line color but we really want the axis color
                        for (let axisTick of tickMarks) {
                            const { y1 } = axisTick;
                            ctx.beginPath();
                            ctx.moveTo(right - (grid.tickMarkLength || 5), y1);
                            ctx.lineTo(right, y1);
                            ctx.stroke();
                        }
                    }
                }
            }
        }
    };

    applyEvents = (settings = {}, chartConfig = {}, interactionOptions = {}) => {
        if (
            settings.eventsGenerate !== undefined &&
            settings.eventsGenerate !== null &&
            settings.eventsGenerate !== ''
        ) {
            if (
                settings.eventsGenerate.toLowerCase().indexOf('mouseenter') >= 0 ||
                settings.eventsGenerate.toLowerCase().indexOf('mouseleave') >= 0 ||
                settings.eventsGenerate.toLowerCase().indexOf('hover') >= 0
            ) {
                chartConfig.options.onHover = this.fireHoverEvent;
            }
            if (settings.eventsGenerate.toLowerCase().indexOf('click') >= 0) {
                chartConfig.options.onClick = this.fireClickEvent;
            }
        }
        if (
            settings.eventsListen !== undefined &&
            settings.eventsListen !== null &&
            settings.eventsListen.toString().toLowerCase() !== 'none'
        ) {
            const types = settings.eventsListen
                    .toString()
                    .toLowerCase()
                    .replace('hover', 'mousemove,mouseleave,mouseenter,mouseout,hover')
                    .split(/[\s,]/),
                widgetsContainer = this.container.closest('.ia-report-widget').parentNode;
            if (widgetsContainer !== undefined && widgetsContainer !== null) {
                for (let et of types) {
                    widgetsContainer.addEventListener(`rb.${et}`, this.onWidgetEvent);
                }
            }
        }
        chartConfig.options.onResize = (c, s) => {
            this.onResponsiveEvent(c, s, { ...settings });
        };
    };

    fireHoverEvent = (e, chartElements, chart) => {
        fireEvent(this, undefined, e, chartElements, chart, this.container);
    };

    fireClickEvent = (e, chartElements, chart) => {
        fireEvent(this, 'click', e, chartElements, chart, this.container);
    };

    onWidgetEvent = (e) => {
        if (e.detail !== undefined && e.detail !== null && e.detail.src !== undefined && e.detail.src !== null) {
            if (this.chart !== undefined && this.chart !== null && e.detail.src.id !== this.design.id) {
                const { selectionMode = 'none' } = this.design,
                    fid = e.detail.feature.id,
                    chartData = this.chart.config.data;
                let selectedSet =
                    e.type === 'rb.mousemove' ||
                    e.type === 'rb.hover' ||
                    e.type === 'rb.mouseout' ||
                    e.type === 'rb.mouseleave' ||
                    selectionMode !== 'multiple'
                        ? []
                        : this.chart.getActiveElements();
                if (chartData !== undefined && chartData.keys !== undefined) {
                    const findex = chartData.keys.indexOf(fid);
                    if (findex >= 0) {
                        for (let i = 0; i < chartData.datasets.length; i++) {
                            selectedSet.push({
                                datasetIndex: i,
                                index: findex
                            });
                        }
                    }
                }
                try {
                    this.chart.setActiveElements(selectedSet);
                    this.chart.update();
                } catch (unexpectedWidgetEx) {
                    console.debug('Non-fatal error on widget event: ' + unexpectedWidgetEx);
                }
            }
        }
    };

    applyChartActions = (settings = {}, data = []) => {
        if (this.container !== null) {
            const cexSelector = '.ia-chart-export-link',
                links = this.container.querySelectorAll(cexSelector),
                exportLabelText =
                    settings.exportLabel !== undefined && settings.exportLabel !== null
                        ? settings.exportLabel
                        : getMessage(
                              'widget.chart.label.export',
                              settings.locale || 'en',
                              'Export this chart as an image'
                          );
            for (let el of links) {
                el.remove();
            }
            const doc = this.container.ownerDocument;
            if (settings.exportable && this.chart !== null) {
                const a = doc.createElement('a'),
                    i = doc.createElement('i'),
                    s = doc.createElement('span'),
                    myChart = this.chart;
                a.setAttribute('href', `#chart${settings.id}`);
                a.setAttribute('class', `${cexSelector.replace('.', '')} pure-tip pure-tip-top-left pure-tip-snapshot`);
                a.setAttribute('data-tooltip', exportLabelText);
                //a.setAttribute('style', 'position: absolute; z-index: 101; bottom: 5px; right: 5px;');
                a.setAttribute('download', settings.exportFileName || 'chart.png');
                i.setAttribute('class', 'fas fa-fw fa-camera');
                i.setAttribute('aria-hidden', 'true');
                a.appendChild(i);
                s.setAttribute('class', 'sr-only');
                s.appendChild(doc.createTextNode(exportLabelText));
                a.appendChild(s);
                this.container.appendChild(a);
                const createBlobFunc = (e) => {
                    const ae = e.target.closest(cexSelector);
                    if (ae.getAttribute('href').indexOf('data:image') !== 0) {
                        let composite = false;
                        if (Array.isArray(myChart) && myChart.length > 0) {
                            // More than one chart - needs a composite (canvas > figure > div)
                            const holder =
                                    myChart[0].chart !== undefined
                                        ? myChart[0].chart.canvas.offsetParent
                                        : myChart[0].canvas.offsetParent,
                                container = holder !== undefined && holder !== null ? holder.offsetParent : null,
                                canvas = container != null ? container.ownerDocument.createElement('canvas') : null;
                            if (canvas !== null) {
                                canvas.setAttribute('width', container.offsetWidth.toFixed(0));
                                canvas.setAttribute('height', container.offsetHeight.toFixed(0));
                                canvas.setAttribute('style', 'position: fixed; left: -200vw; top: -200vh;');
                                const ctx = canvas.getContext('2d');
                                ctx.fillStyle = '#ffffff';
                                ctx.fillRect(0, 0, canvas.width, canvas.height);
                                if (typeof ctx.imageSmoothingQuality !== 'undefined')
                                    ctx.imageSmoothingQuality = 'high';
                                container.ownerDocument.body.appendChild(canvas);
                                let offset = 0,
                                    iat = 0;
                                const afterAll = () => {
                                    iat++;
                                    if (iat >= myChart.length) {
                                        const bigBase64 = canvas.toDataURL();
                                        //console.log(bigBase64); // DEBUG
                                        ae.setAttribute('href', bigBase64);
                                        canvas.remove();
                                    }
                                };
                                for (let c of myChart) {
                                    const co = c.chart !== undefined ? c.chart : c,
                                        base64 = co.toBase64Image(),
                                        cc = co.canvas,
                                        cx = offset,
                                        cw = cc.offsetWidth,
                                        ch = cc.offsetHeight,
                                        image = new Image();
                                    image.onload = () => {
                                        ctx.drawImage(image, cx, 0, cw, ch);
                                        afterAll();
                                    };
                                    image.src = base64;
                                    offset += cw;
                                }
                                composite = true;
                            }
                        }
                        if (!composite) {
                            const base64 = myChart.toBase64Image();
                            ae.setAttribute('href', base64);
                        }
                    }
                };
                a.addEventListener('mouseover', createBlobFunc);
                a.addEventListener('focus', createBlobFunc);
            }
            this.appendAlternateViewActions(settings, data);
        }
    };

    static getPaletteColors = (paletteSettingsText, fallbackColors) => {
        const paletteText =
            paletteSettingsText !== undefined
                ? Array.isArray(paletteSettingsText)
                    ? paletteSettingsText.join(' ')
                    : paletteSettingsText
                : Array.isArray(fallbackColors)
                ? fallbackColors.join(' ')
                : fallbackColors;
        if (paletteText === undefined || paletteText === null || paletteText.trim() === '') return null;
        if (paletteText.indexOf('=') > 0) {
            const keyed = AbstractChartWidget.getKeyedPaletteColors(paletteSettingsText, true);
            if (keyed !== null) {
                return Array.from(keyed.values);
            }
        }
        let tokens = paletteText
                .split(paletteText.indexOf('rgb(') >= 0 || paletteText.indexOf('rgba(') >= 0 ? /[\s]/ : /[,\s]/)
                .filter((h) => h !== ''),
            index,
            hex,
            colors = new Array(tokens.length),
            namedPaletteIndex = tokens.length === 1 ? STANDARD_PALETTES_KEYS.indexOf(tokens[0]) : -1;
        if (namedPaletteIndex >= 0) return STANDARD_PALETTES[namedPaletteIndex]; // Legacy version - should not be using this now but RB1.x did...
        for (let i = 0; i < tokens.length; i++) {
            index = i;
            hex = tokens[i];
            if (tokens[i].indexOf('=') > 0) {
                hex = tokens[i].substring(tokens[i].indexOf('=') + 1);
                index = parseInt(tokens[i].substring(0, tokens[i].indexOf('='))) - 1;
            }
            colors[index] = parseColor(hex);
        }
        return colors;
    };

    static getKeyedPaletteColors = (paletteSettingsText, skipIfNotExplicitlyKeyed = true) => {
        const paletteText =
            paletteSettingsText !== undefined
                ? Array.isArray(paletteSettingsText)
                    ? paletteSettingsText.join(' ')
                    : paletteSettingsText
                : undefined;
        if (
            paletteText === undefined ||
            paletteText === null ||
            paletteText.trim() === '' ||
            (paletteText.indexOf('=') < 0 && skipIfNotExplicitlyKeyed)
        )
            return null;
        let tokens = paletteText.split(
                paletteText.indexOf('rgb(') >= 0 || paletteText.indexOf('rgba(') >= 0 ? /[\s]/ : /[,\s]/
            ),
            key,
            hex,
            colors = new Map();
        // This is simpler than dealing with silly .split() regular expressions to detect text escapes,
        // a whitespace split will turn "Very Good"=#ff0000 into ['"Very', 'Good"=#ff0000'] so we reverse some of that...
        let i = tokens.length - 1;
        while (i >= 0) {
            while (
                i >= 0 &&
                ((tokens[i].indexOf(`'`) > 0 && tokens[i].indexOf('=') > 0 && tokens[i - 1].indexOf(`=`) < 0) ||
                    (tokens[i].indexOf(`"`) > 0 && tokens[i].indexOf('=') > 0 && tokens[i - 1].indexOf(`=`) < 0))
            ) {
                tokens[i - 1] = `${tokens[i - 1]} ${tokens[i]}`;
                tokens.splice(i, 1);
                i--;
            }
            i--;
        }
        for (let i = 0; i < tokens.length; i++) {
            key = i.toString();
            hex = tokens[i];
            if (tokens[i].indexOf('=') > 0) {
                hex = parseColor(tokens[i].substring(tokens[i].indexOf('=') + 1));
                key = tokens[i].substring(0, tokens[i].indexOf('=')).replace(/["']/g, '');
            }
            colors.set(key, hex);
        }
        return colors;
    };

    /**
     * Function designed to be applied inside the preRender: (chartConfig) function to update data point labels.
     * Relies on ther ebeing the correct plugin "datalabels".
     * @param {*} settings The chart settings (widget settings).
     * @param {*} chartConfig The config for the chart (chart data is assumed to be in its final form here).
     * @param {*} displayFunc An optional override to give more control over the display of the labels.
     */
    applyDataPointLabels = (
        settings = {},
        chartConfig = {},
        displayFunc = (c) => {
            return true;
        },
        formatterFunc,
        chartData
    ) => {
        const {
                labelDataPointsFormat: dpl = '#VALY',
                numberFormat = '#.#',
                labelDataPointsAnchor,
                labelDataPointsOffset = '3px',
                yAxisReversed,
                axisFontFamily = 'sans-serif',
                axisFontSize = '12px',
                labelDataPointsColor,
                labelDataPointsFontSize,
                labelDataPointsStyle = 'text', // text | badge
                labelDataPointsBorderColor = '#ffffff',
                labelDataPointsBorderRadius = '25px',
                labelDataPointsBorderWidth = '2px',
                labelDataPointsAlign,
                labelDataPointsRotation = 0,
                dataIndexOffset = 0,
                noDataText = '',
                useStackedBars = false,
                alwaysStackTo100 = false,
                seriesBindingStyle = 'features-as-series',
                yAxisLabelFormat
            } = settings,
            nfmt = getNumberFormatter(
                settings.locale,
                yAxisLabelFormat !== undefined ? yAxisLabelFormat.replace(/^\[blank\]$/, '') : numberFormat
            ),
            isPerc = dpl.indexOf('#PERCENT') >= 0,
            customFmt = buildFormattersFromLabel(
                dpl,
                settings.locale,
                dpl !== '' ? getNumberFormatter(settings.locale, numberFormat) : nfmt
            ),
            displayLabel = dpl.replace(/\{[0#,.A-Z]+\}/g, ''); //(dpl.indexOf('{') >= 0 ? dpl.substring(0, dpl.indexOf('{')) + dpl.substring(dpl.indexOf('}') + 1) : dpl);
        chartConfig.options.plugins = chartConfig.options.plugins || {};
        chartConfig.options.plugins.datalabels = {
            enabled: true,
            display: displayFunc,
            formatter:
                formatterFunc !== undefined
                    ? formatterFunc
                    : (value, context) => {
                          // Added so that fillTo100 pie chart segments dot get labelled.
                          if (
                              (context.chart.config.type === 'pie' || context.chart.config.type === 'doughnut') &&
                              context.chart.data.labels[context.dataIndex] === undefined
                          )
                              return '';

                          const hasCalculatedData =
                                  context.chart &&
                                  context.chart.data &&
                                  context.chart.data.originalData &&
                                  context.chart.data.calculatedData,
                              dataBundle = hasCalculatedData
                                  ? context.chart.data.originalData[context.datasetIndex]
                                  : context.dataset.data,
                              trueDataVals = dataBundle[context.dataIndex] || {},
                              rawValue = hasCalculatedData ? trueDataVals : value,
                              v =
                                  typeof rawValue === 'number'
                                      ? rawValue
                                      : typeof rawValue === 'object' && rawValue !== null && rawValue.y !== undefined
                                      ? rawValue.y
                                      : typeof rawValue === 'string' && !isNaN(parseFloat(rawValue))
                                      ? parseFloat(rawValue)
                                      : NaN;
                          let fv = '';
                          let fperc = 0;
                          if (isPerc && !isNaN(v)) {
                              if (alwaysStackTo100 && hasCalculatedData) {
                                  const pv = context.chart.data.calculatedData[context.datasetIndex][context.dataIndex],
                                      pnv =
                                          typeof pv === 'number'
                                              ? pv
                                              : typeof pv === 'object' && pv !== null && pv.y !== undefined
                                              ? pv.y
                                              : typeof pv === 'string' && !isNaN(parseFloat(pv))
                                              ? parseFloat(pv)
                                              : NaN;
                                  fperc = `${(customFmt['PERCENT'] || customFmt._def).format(pnv)}`;
                              } else {
                                  let sum = 0;
                                  if (useStackedBars && seriesBindingStyle === 'indicators-as-series') {
                                      // Stacked bars but across datasets
                                      for (let d of context.chart.data.datasets) {
                                          const i = d.data[context.dataIndex];
                                          sum += i != null ? (i.y !== undefined ? i.y : i) : 0;
                                      }
                                  } else {
                                      for (let i of dataBundle) {
                                          sum += i != null ? (i.y !== undefined ? i.y : i) : 0;
                                      }
                                  }
                                  let perc = (v * 100.0) / sum;
                                  fv = v;
                                  fperc = `${(customFmt['PERCENT'] || customFmt._def).format(perc)}`;
                              }
                          } else if (isNaN(v)) {
                              fv = '';
                          } else {
                              fv = (customFmt['VALY'] || customFmt['YVAL'] || customFmt._def).format(v); // ${chartConfig.data.labels[context.dataIndex]}
                          }
                          fv =
                              displayLabel.indexOf('#') >= 0
                                  ? displayLabel
                                        .replace(
                                            /#VALY|#YVAL/gi,
                                            (customFmt['VALY'] || customFmt['YVAL'] || customFmt._def).format(
                                                trueDataVals.y !== undefined && !isNaN(trueDataVals.y)
                                                    ? trueDataVals.y
                                                    : !isNaN(v)
                                                    ? v
                                                    : fv
                                            )
                                        )
                                        .replace(
                                            /#MINY|#YMIN|#LL/g,
                                            trueDataVals.yMin !== undefined && !isNaN(trueDataVals.yMin)
                                                ? customFmt._def.format(trueDataVals.yMin)
                                                : noDataText
                                        )
                                        .replace(
                                            /#MAXY|#YMAX|#UL/g,
                                            trueDataVals.yMax !== undefined && !isNaN(trueDataVals.yMax)
                                                ? customFmt._def.format(trueDataVals.yMax)
                                                : noDataText
                                        )
                                        .replace(
                                            /#VALZ|#ZVAL|#SIZE/g,
                                            trueDataVals.v !== undefined && !isNaN(trueDataVals.v)
                                                ? (customFmt['VALZ'] || customFmt['ZVAL'] || customFmt._def).format(
                                                      trueDataVals.v
                                                  )
                                                : noDataText
                                        )
                                        .replace(
                                            /#VALX|XVAL/gi,
                                            trueDataVals.x !== undefined && !isNaN(trueDataVals.x)
                                                ? (customFmt['VALX'] || customFmt['XVAL'] || customFmt._def).format(
                                                      trueDataVals.x
                                                  )
                                                : context.chart.data.labels[context.dataIndex]
                                        )
                                        .replace(/#VALX|#LABEL/gi, context.chart.data.labels[context.dataIndex])
                                        .replace(/#VAL/g, fv)
                                        .replace(/#PERCENT(\{.*\})?/g, `${fperc}%`)
                                        .replace(/#SERIES|#SER|#LEGENDTEXT/g, context.dataset.label) // Already handled by the title (above)
                                        .replace(
                                            /#INDEXSYM|#IDXSYM|#DATASETSYM/g,
                                            '①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⓪'.charAt(context.datasetIndex + dataIndexOffset)
                                        )
                                        .replace(
                                            /#INDEXSUP|#IDXSUP|#DATASETSUP/g,
                                            '¹²³⁴⁵⁶⁷⁸⁹⁰'.charAt(context.datasetIndex + dataIndexOffset)
                                        )
                                        .replace(
                                            /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                            'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(
                                                context.datasetIndex + dataIndexOffset
                                            )
                                        )
                                        .replace(
                                            /#INDEX|#IDX|#DATASET/g,
                                            (context.datasetIndex + 1 + dataIndexOffset).toFixed(0)
                                        )
                                        .replace(
                                            /#POINTINDEXSYM|#PIDXSYM/g,
                                            '①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⓪'.charAt(context.dataIndex)
                                        )
                                        .replace(
                                            /#POINTINDEXALPHA|#POINTINDEXA|#PIDXA/g,
                                            'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(context.dataIndex)
                                        )
                                        .replace(/#POINTINDEX|#PIDX/g, (context.dataIndex + 1).toFixed(0))
                                        .replace('%%', '%') // Deal with inconsistent user ability
                                        .replace(/#NAME/g, context.chart.data.labels[context.dataIndex])
                                  : fv;
                          if (fv.indexOf('\n') > 0 || fv.indexOf('\\n') > 0) fv = fv.split(/\n|\\n/);
                          // #20609 Fix for empty string displaying empty badge in bar charts
                          if (
                              context.chart.config.type.length >= 3 &&
                              context.chart.config.type.substring(0, 3) === 'bar' &&
                              fv === ''
                          )
                              fv = null;
                          return fv;
                      },
            offset: convertLengthToPixels(labelDataPointsOffset, true, true),
            anchor: labelDataPointsAnchor !== undefined ? labelDataPointsAnchor : yAxisReversed ? 'start' : 'end',
            align: labelDataPointsAlign !== undefined ? labelDataPointsAlign : yAxisReversed ? 'start' : 'end',
            textAlign: 'center',
            clamp: true,
            font: {
                family: axisFontFamily,
                size: convertLengthToPixels(labelDataPointsFontSize || axisFontSize, true, true)
            },
            rotation: labelDataPointsRotation
        };
        // Specialist...may not apply to all charts
        if (labelDataPointsStyle === 'badge' || labelDataPointsStyle === 'panel') {
            chartConfig.options.plugins.datalabels.backgroundColor = (context) => {
                return context.dataset.backgroundColor || context.dataset.borderColor; // Lines only have border...
            };
            chartConfig.options.plugins.datalabels.padding = {
                top: 4,
                bottom: 4,
                right: 10,
                left: 10
            };
            chartConfig.options.plugins.datalabels.borderColor = labelDataPointsBorderColor;
            chartConfig.options.plugins.datalabels.borderRadius = convertLengthToPixels(
                labelDataPointsBorderRadius,
                true,
                true
            );
            chartConfig.options.plugins.datalabels.borderWidth = convertLengthToPixels(
                labelDataPointsBorderWidth,
                true,
                true
            );
            chartConfig.options.plugins.datalabels.color = labelDataPointsBorderColor; // Match border by default, but let them override
        }
        if (labelDataPointsColor !== undefined && labelDataPointsColor !== '')
            chartConfig.options.plugins.datalabels.color = settings.labelDataPointsColor;
    };
}

const buildFormattersFromLabel = (label, locale, defaultFormatter) => {
    const fmts = {
            _def: defaultFormatter
        },
        tokens = label.split(/#((XVAL|YVAL|ZVAL|VALX|VALY|VALZ|VALUE|XMIN|YMAIN)\{[0#,.A-Z]+\})/);
    for (let t of tokens) {
        if (t.indexOf('{') > 0) {
            const key = t.substring(0, t.indexOf('{')),
                nfmt = getNumberFormatter(locale, t.substring(t.indexOf('{') + 1, t.indexOf('}')));
            fmts[key] = nfmt;
        }
    }
    return fmts;
};

const fireEvent = (widget, eventType, srcEvent, chartElements = [], chart, container) => {
    if (chartElements.length > 0) {
        const ce = chartElements.pop(),
            { index, datasetIndex } = ce,
            dataset = chart.data.datasets[datasetIndex],
            indicator = dataset.indicator,
            xName = chart.data.labels[index],
            xId =
                chart.data.keys !== undefined && chart.data.keys.length === chart.data.labels.length
                    ? chart.data.keys[index]
                    : undefined,
            xValue = {
                id: xId,
                name: Array.isArray(xName) ? xName.join(' ') : xName
            },
            yValue = dataset.data[index],
            f = dataset.feature !== undefined ? dataset.feature : xValue; // dependent on the series binding

        // TODO - deal with the series binding to really detect what the IDs and names mean/relate to...
        widget.fireEvent(eventType !== undefined ? eventType : srcEvent.type, {
            data: {
                type: 'chart',
                x: xValue,
                y: yValue,
                series: {
                    name: dataset.label
                },
                color:
                    dataset.backgroundColor !== undefined
                        ? Array.isArray(dataset.backgroundColor)
                            ? dataset.backgroundColor[index]
                            : dataset.backgroundColor
                        : null
            },
            src: {
                id: widget.design.id,
                type: (widget.design.scriptClass || widget.constructor.name).replace('Widget', ''),
                event: srcEvent
            },
            feature: f,
            indicator
        });
    }
};

export const getMaxTextWidth = (
    canvasContext2d,
    texts = [],
    fontFamily = 'Arial',
    fontSize = '12px',
    fontWeight = 'normal'
) => {
    let maxWidth = 0;
    canvasContext2d.font = `${fontWeight} ${lengthWithUnit(fontSize)} "${fontFamily}"`;
    for (let t of texts) {
        maxWidth = Math.max(maxWidth, canvasContext2d.measureText((Array.isArray(t) ? t.join(' ') : t).trim()).width);
    }
    return maxWidth;
};

export const getBestTextForWidth = (
    canvasContext2d,
    textAsString = '',
    maxWidth = 100,
    fontFamily = 'Arial',
    fontSize = '12px',
    fontWeight = 'normal',
    noSplitIfShorterThan = 6
) => {
    const trueString = Array.isArray(textAsString) ? textAsString.join(' ') : textAsString,
        tokens = trueString.length > noSplitIfShorterThan ? trueString.split(/\s/) : [trueString],
        lines = [];
    canvasContext2d.font = `${fontWeight} ${lengthWithUnit(fontSize)} "${fontFamily}"`;
    let i = 0;
    while (i < tokens.length) {
        let line = `${tokens[i]}`;
        let j = i + 1;
        while (j < tokens.length) {
            if (canvasContext2d.measureText(`${line} ${tokens[j]}`.trim()).width <= maxWidth) {
                line = `${line} ${tokens[j]}`.trim();
                i = j;
                j++;
            } else {
                lines.push(line);
                j = tokens.length;
            }
        }
        if (lines.indexOf(line) < 0) lines.push(line);
        i++;
    }
    // Re-measure - do we _really_ fit?
    let fit = true;
    for (let ln of lines) {
        fit = fit && canvasContext2d.measureText(ln.trim()).width <= maxWidth;
    }
    return {
        lines,
        fit
    };
};

export const createSwatchImage = (doc, color = '#ff0000', imageSizeInPixels = { width: 32, height: 16 }) => {
    const canvas = doc.createElement('canvas');
    canvas.width = imageSizeInPixels.width;
    canvas.height = imageSizeInPixels.height;
    canvas.setAttribute(
        'style',
        `position: absolute; left: -1000px; top: -1000px; width: ${imageSizeInPixels.width}px; height: ${imageSizeInPixels.height}px;`
    );
    doc.documentElement.appendChild(canvas);
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, imageSizeInPixels.width, imageSizeInPixels.height);
    const img = new Image(imageSizeInPixels.width, imageSizeInPixels.height);
    img.src = canvas.toDataURL();
    canvas.remove();
    return img;
};

export const getPointStyle = (genericPointStyle = 'circle', seriesIndex = 0, flipOrder = false) => {
    const keys = 'circle|diamond|square|rounded-square|star|triangle|cross|cross-x'.split('|'),
        values = 'circle|rectRot|rect|rectRounded|star|triangle|cross|crossRot'.split('|'), // chartjs specific and not very pretty
        offset = seriesIndex % keys.length;
    if (flipOrder) {
        keys.reverse();
        values.reverse();
    }
    const idx = keys.indexOf(genericPointStyle.toLowerCase());
    if (genericPointStyle.toLowerCase() === 'auto') return values[offset];
    else if (idx >= 0) return values[idx];
    else return values[0];
};

export const createDashedLine = (pattern = 'dash', lineWidth = 2) => {
    let dash = []; // solid line, also Dot, Dash, Solid, DashDot
    // See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
    switch (pattern.toLowerCase()) {
        case 'dotted':
        case 'dot':
            dash = [lineWidth, lineWidth];
            break;
        case 'dashed':
        case 'dash':
            dash = [10, 5];
            break;
        case 'long-dashed':
        case 'long-dash':
            dash = [20, 5];
            break;
        case 'dot-dash':
        case 'dash-dot':
        case 'dashdot':
            dash = [15, 3, lineWidth, 3];
            break;
        default:
            dash = [];
            break;
    }
    return dash;
};
