import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createReportBuilderStore } from './redux/builderStore';
import {
    BrowserRouter,
    HashRouter,
    StaticRouter,
    MemoryRouter,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import ConfirmNavigationDialog from './components/ConfirmNavigationDialog';
import ProgressMessage from './components/ProgressMessage';
import AppNavigationBar from './components/AppNavigationBar';
import AppFooter from './components/AppFooter';
//import PageNavigatorButtons from 'components/PageNavigatorButtons';
import './assets/styles/ReportBuilderApp.scss';
import './assets/styles/print-defaults.scss';

import './assets/styles/index.css';

import { useIntl } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isNullOrUndefined } from './utils/object';
// Import these ones always to avoid script load complexity
import ReportViewIndexPage from './pages/ReportViewIndexPage';
//import ReportViewPage from './pages/ReportViewPage';
import ReportViewPage from './pages/ReportViewPage';
import { generateGuid } from './utils/report-migrate-plus';
import { IAppConfig, RB_APP_VIEW_ONLY_AUTH_ID } from './utils/appUtilsAndConstants';
import esriId from '@arcgis/core/identity/IdentityManager.js';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo.js';

import '@esri/calcite-components/dist/components/calcite-shell';
import { CalciteShell } from '@esri/calcite-components-react';

// Load these lazily because they make no sense if not launched in "full app" mode
const HomePage = lazy(() => import('./pages/HomePage'));
const AppCannotStartPage = lazy(() => import('./pages/AppCannotStartPage'));
const ReportEditPage = lazy(() => import('./pages/ReportEditPage'));
const ReportPreviewPage = lazy(() => import('./pages/ReportPreviewPage'));
const ReportsComparePage = lazy(() => import('./pages/ReportsComparePage'));
const PreviewComponentsPage = lazy(() => import('./components/PreviewComponentsPage'));
const NoMatchPage = lazy(() => import('./pages/NoMatchPage'));

interface IReportBuilderAppArgs extends IAppConfig {
    id: string;
    authId?: string;
    homeUrl?: string;
    helpUrl?: string;
    portalUrl?: string;
    portalType?: 'online' | 'portal';
    portalHome?: string;
    staticUrl?: string;
    queryString?: string;
    inErrorState?: boolean;
    extraComponents?: any;
    [key: string]: any;
}

type AppWrapperType = {
    props?: IReportBuilderAppArgs;
    signOutOfApp?: () => void;
};

const ReportBuilderApp = (props: IReportBuilderAppArgs) => {
    const {
            id,
            authId,
            homeUrl = 'https://www.reports.esriuk.com/',
            helpUrl = 'https://help.reports.esriuk.com/report-builder/',
            portalUrl = 'https://www.arcgis.com/sharing/rest',
            portalType = 'online',
            portalHome = 'https://www.arcgis.com/',
            staticUrl,
            token: initialToken,
            tokenExpiry,
            user,
            onReady,
            basename,
            queryString,
            appLinks,
            inErrorState,
            routerMode = 'browser',
            className = '',
            extraComponents,
            location: topLocation,
            viewOnlyAuthId = RB_APP_VIEW_ONLY_AUTH_ID,
            featureSwitches,
            ...others
        } = props,
        [approved, setApproved] = useState(false),
        [loading, setLoading] = useState(true),
        //appOptions = useAppSelector((state) => state.userOptions),
        scrollTopIcon = useRef<HTMLAnchorElement>(null),
        headerBar = useRef<HTMLDivElement>(null),
        builderStore = useRef(createReportBuilderStore()),
        intl = useIntl();
    //dispatch = useAppDispatch();
    //options = !isNullOrUndefined(appOptions) ? appOptions.find((o) => o.id === 'app') : null;

    useEffect(() => {
        const init = async () => {
            const dispatchable: any = {
                type: 'SET_RB_APP_SETTINGS',
                appId: id || '',
                appAuthId: authId || '',
                appHome: homeUrl || '',
                appHelp: helpUrl,
                portalUrl: portalUrl || '',
                portalType: portalType || '',
                portalHome: portalHome || '',
                staticUrl: staticUrl || '',
                token: initialToken || '',
                tokenExpiry: !isNullOrUndefined(tokenExpiry) ? tokenExpiry : -1,
                user: user || {},
                productHome: 'www.reports.esriuk.com',
                featureSwitches: featureSwitches || {}
            };
            builderStore.current.dispatch(dispatchable);
            const oAuthInfo = new OAuthInfo({
                    appId: authId,
                    portalUrl: portalHome
                }),
                registered = [oAuthInfo];
            esriId.registerOAuthInfos(registered);
            esriId.on('dialog-create', () => {
                esriId.dialog.when(() => {
                    // esriId.dialog.content.messages.info = intl.formatMessage({
                    //     id: 'dialog.auth.message',
                    //     defaultMessage: "Please sign in to access data from '{server}'."
                    // });
                });
            });
            // Initial token specified in the URL - this should be used sparingly and generally only for "view" actions
            if (
                initialToken !== undefined &&
                initialToken !== null &&
                initialToken !== '' &&
                esriId !== undefined
            ) {
                esriId.registerToken({
                    server: portalUrl,
                    token: initialToken
                });
                console.debug(`⚠ Hard-wired access token: ${initialToken.substring(0, 9)}...`); // DEBUG
            } else {
                // Token?
                try {
                    const tc = await esriId.checkSignInStatus(portalUrl),
                        portalSelf = await fetch(
                            `${portalUrl.replace(/^\/(.*)\/$/, '$1')}/portals/self?f=json&token=${
                                tc.token
                            }`
                        ).then((portalRsp) => {
                            return portalRsp.json();
                        });
                    dispatchable.token = tc.token;
                    dispatchable.tokenExpiry = tc.expires;
                    tc.on('token-change', (te) => {
                        builderStore.current.dispatch({
                            type: 'SET_RB_APP_SETTINGS',
                            token: tc.token,
                            tokenExpiry: tc.expires
                        });
                    });
                    if (portalSelf.error === undefined) {
                        dispatchable.user = portalSelf.user;
                        if (
                            !isNullOrUndefined(portalSelf.urlKey) &&
                            !isNullOrUndefined(portalSelf.customBaseUrl)
                        )
                            dispatchable.portalUrl =
                                'https://' +
                                portalSelf.urlKey +
                                '.' +
                                portalSelf.customBaseUrl +
                                '/sharing/rest/';
                    }
                    builderStore.current.dispatch(dispatchable);
                } catch (tcex) {
                    // console.log(tcex);
                }
            }
            builderStore.current.dispatch({
                type: 'SET_APPLICATION_STATE',
                tokenManager: esriId
            });
            if (!isNullOrUndefined(id) && !isNullOrUndefined(initialToken) && initialToken !== '') {
                fetch(portalUrl + 'content/items/' + id + '?f=json&token=' + initialToken).then(
                    (itemRsp) => {
                        itemRsp.json().then((itemInfo) => {
                            const okToGo = !itemInfo.error && itemInfo.id && itemInfo.id === id;
                            setApproved(okToGo);
                            setLoading(false);
                        });
                    }
                );
            }
            if (scrollTopIcon && scrollTopIcon.current)
                scrollTopIcon.current.style.display = 'none';
            window.addEventListener('beforeunload', () => {
                builderStore.current.dispatch({ type: 'CLEAR_PAGE_STATE' });
            });
            //window.addEventListener('scroll', this.handleWindowScroll);
            if (onReady !== undefined && onReady !== null) onReady(null, {}, {});
        };
        init();
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, [
        id,
        authId,
        homeUrl,
        helpUrl,
        portalUrl,
        portalType,
        portalHome,
        staticUrl,
        initialToken,
        tokenExpiry,
        user,
        onReady,
        intl,
        featureSwitches
    ]);

    const handleWindowScroll = (scrollEvt) => {
        if (scrollTopIcon.current && headerBar.current) {
            const activeScroll =
                document.scrollingElement !== null
                    ? document.scrollingElement.scrollTop
                    : Number.MIN_SAFE_INTEGER;
            if (activeScroll !== Number.MIN_SAFE_INTEGER) {
                const headerOffset = window.outerHeight; //this.headerBar.current.getBoundingClientRect().height;
                scrollTopIcon.current.style.display =
                    activeScroll < headerOffset ? 'none' : 'block';
            }
        }
    };

    const resetWindowScroll = () => {
        window.scrollTo(0, 0);
    };

    const signOutOfApp = () => {
        esriId.destroyCredentials();
        // 1st app load - set some globals (peristed in sessionStorage)
        builderStore.current.dispatch({
            type: 'SET_RB_APP_SETTINGS',
            appId: id || '',
            appAuthId: authId || '',
            portalUrl: portalUrl || '',
            portalType: portalType || '',
            portalHome: portalHome || '',
            token: '',
            tokenExpiry: -1,
            user: null
        });
        // 1st app load - clear some page state (not persisted)
        builderStore.current.dispatch({
            type: 'SET_PAGE_STATE',
            custom: null
        });
        // signOut(initialToken, authId, portalUrl).then(() => {
        window.setTimeout(() => {
            if (window.location.pathname !== (basename || '/'))
                window.location.href = (basename || '/').split('#')[0].split('?')[0]; // +
            // '?action=sign-out&t=' +
            // new Date().getTime().toFixed(0).substring(0, 12);
        }, 50);
        // });
    };

    const appSkinClass = 'normal', //!isNullOrUndefined(options) ? options.skin : 'normal',
        appWrapper = {
            props: { ...props },
            signOutOfApp: signOutOfApp
        },
        landingPageComponent = inErrorState ? AppCannotStartPage : HomePage,
        appUuid = generateGuid(),
        omitLandmarks =
            others.windowMode !== undefined &&
            others.windowMode !== null &&
            others.windowMode === 'embedded',
        appInDev =
            typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            !isNullOrUndefined(process.env.NODE_ENV) &&
            process.env.NODE_ENV === 'development',
        isTouchDevice = window.matchMedia('(hover: none) and (pointer: coarse)').matches; //ontouchstart' in window || navigator.maxTouchPoints > 0;

    return (
        <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
            <Provider store={builderStore.current}>
                <AppRouter
                    basename={
                        basename ||
                        (typeof process !== 'undefined' && typeof process.env !== 'undefined'
                            ? process.env.PUBLIC_URL
                            : '/')
                    }
                    queryString={queryString}
                    keyLength={24}
                    mode={routerMode}
                    location={topLocation}
                >
                    <div
                        id={`iaAppRoot${appUuid}`}
                        className={`esriuk-app esriuk-app-body ia-app ia-app-body skin-${appSkinClass} ${className}`}
                        ref={headerBar}
                    >
                        <Suspense
                            fallback={
                                <div className="progress-big">
                                    <ProgressMessage message="" />
                                </div>
                            }
                        >
                            {extraComponents}
                            <Switch>
                                <RouteWithLayout
                                    exact
                                    path="/"
                                    component={landingPageComponent}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="home no-header"
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    footer="full"
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/edit-report/:id([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportEditPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header edit-page"
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    minimal={true}
                                    {...others}
                                />
                                <Redirect
                                    from="/report/edit/:id([a-zA-Z0-9\_\-]+)"
                                    to="/edit-report/:id"
                                />
                                <Redirect
                                    from="/edit-report/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    to="/edit-report/:id"
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-local/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="single"
                                    dataMode="local"
                                    terms={false}
                                    landmarks={!omitLandmarks}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-publication/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="single"
                                    dataMode="static"
                                    terms={false}
                                    landmarks={!omitLandmarks}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-publication/:id([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewIndexPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page index-page"
                                    dataMode="static"
                                    terms={false}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-report/:id([a-zA-Z0-9\_\-]+)/aggregate"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="aggregate"
                                    terms={false}
                                    appAuthenticationId={viewOnlyAuthId}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-report/:id([a-zA-Z0-9\_\-]+)/multiple"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="aggregate"
                                    terms={false}
                                    appAuthenticationId={viewOnlyAuthId}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-report/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="single"
                                    terms={false}
                                    landmarks={!omitLandmarks}
                                    appAuthenticationId={viewOnlyAuthId}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-report/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)/:layer([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="single"
                                    terms={false}
                                    landmarks={!omitLandmarks}
                                    appAuthenticationId={viewOnlyAuthId}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <Redirect
                                    from="/report/view/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    to="/view-report/:id/:feature"
                                />
                                <Redirect
                                    from="/:id([0-9a-fA-F]{32})/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    to="/view-report/:id/:feature"
                                />
                                <RouteWithLayout
                                    exact
                                    path="/view-report/:id([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewIndexPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page index-page"
                                    terms={false}
                                    landmarks={!omitLandmarks}
                                    appAuthenticationId={viewOnlyAuthId}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <Redirect
                                    from="/report/view/:id([a-zA-Z0-9\_\-]+)"
                                    to="/view-report/:id"
                                />
                                <RouteWithLayout
                                    exact
                                    path="/preview-report/:id([a-zA-Z0-9\_\-]+)/:feature+"
                                    component={ReportPreviewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header preview-page"
                                    viewer={`${
                                        basename ||
                                        (typeof process !== 'undefined' &&
                                        typeof process.env !== 'undefined'
                                            ? process.env.PUBLIC_URL
                                            : '')
                                    }/view-report`}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/preview-report/:id([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportPreviewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header preview-page"
                                    viewer={`${
                                        basename ||
                                        (typeof process !== 'undefined' &&
                                        typeof process.env !== 'undefined'
                                            ? process.env.PUBLIC_URL
                                            : '')
                                    }/view-report`}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/compare-reports/:id([a-zA-Z0-9\_\-]+)/:comp([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportsComparePage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header preview-page"
                                    viewer={`${
                                        basename ||
                                        (typeof process !== 'undefined' &&
                                        typeof process.env !== 'undefined'
                                            ? process.env.PUBLIC_URL
                                            : '')
                                    }/view-report`}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    path="/embed-report/:id([a-zA-Z0-9\_\-]+)/:feature([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="single"
                                    navBar={false}
                                    footer="none"
                                    terms={false}
                                    lockNavigation={true}
                                    landmarks={!omitLandmarks}
                                    appAuthenticationId={viewOnlyAuthId}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                <RouteWithLayout
                                    path="/embed-publication/:id([a-zA-Z0-9\_\-]+)/:feature+"
                                    component={ReportViewPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="child-page no-header no-nav view-page"
                                    mode="single"
                                    dataMode="static"
                                    navBar={false}
                                    footer="none"
                                    terms={false}
                                    lockNavigation={true}
                                    landmarks={!omitLandmarks}
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                    {...others}
                                />
                                {appInDev && (
                                    <RouteWithLayout
                                        path="/preview-components"
                                        component={PreviewComponentsPage}
                                        app={appWrapper}
                                        appLinks={appLinks}
                                        className="child-page no-header no-nav view-page"
                                        homeUrl={homeUrl}
                                        helpUrl={helpUrl}
                                    />
                                )}
                                <RouteWithLayout
                                    path="*"
                                    component={NoMatchPage}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    className="home no-header"
                                    homeUrl={homeUrl}
                                    helpUrl={helpUrl}
                                />
                            </Switch>
                        </Suspense>
                        <a
                            href={`#iaAppRoot${appUuid}`}
                            ref={scrollTopIcon}
                            onClick={(e) => {
                                document
                                    .getElementById(`iaAppRoot${appUuid}`)
                                    ?.scrollIntoView({ behavior: 'smooth' });
                                e.preventDefault();
                            }}
                            className="to-top-link nodef"
                        >
                            <i aria-hidden="true" className="fas fa-chevron-circle-up"></i>
                            <span className="sr-only">Back to top of page</span>
                        </a>
                    </div>
                </AppRouter>
            </Provider>
        </DndProvider>
    );
};

const AppRouter = ({
    basename,
    queryString,
    keyLength,
    mode = 'browser',
    children,
    location = '/'
}) => {
    return mode === 'static' ? (
        <StaticRouter location={{ pathname: location }}>{children}</StaticRouter>
    ) : mode === 'hash' ? (
        <HashRouter
            basename={basename}
            // getUserConfirmation={(message, callback) => {
            //     ConfirmNavigationDialog(message, callback);
            // }}
        >
            {children}
        </HashRouter>
    ) : mode === 'memory' || mode === 'silent' ? (
        <MemoryRouter initialEntries={[location]} initialIndex={0}>
            {children}
        </MemoryRouter>
    ) : (
        <BrowserRouter
            basename={
                basename ||
                (typeof process !== 'undefined' && typeof process.env !== 'undefined'
                    ? process.env.PUBLIC_URL
                    : '')
            }
            keyLength={24}
            getUserConfirmation={(message, callback) => {
                ConfirmNavigationDialog(message, callback);
            }}
        >
            {children}
        </BrowserRouter>
    );
};

/* <UserOptions signOutAction={appWrapper.signOutOfApp} showOptions={false} /> */
/* <PageTitle updateDocument={true} /><PageTitle textKey="group" icon="" className="customer-name" /><PageTitle textKey="item" icon="" className="map-name" /> */
const RouteWithLayout = ({
    component: Component,
    app: appWrapper = {},
    className: routeClassName,
    footer = 'minimal', // or "full" or "none" or "true" or "false"
    terms = true,
    landmarks = true,
    navBar = true,
    minimal = false, // If minimal, skip most of the layout and let the component do it itself (useful for pages with custom or extended navbars)
    ...rest
}: {
    component: any;
    app?: AppWrapperType;
    className?: string;
    footer?: 'minimal' | 'full' | 'none';
    terms?: boolean;
    landmarks?: boolean;
    navBar?: boolean;
    minimal?: boolean;
    [id: string]: any;
}) => {
    const rootPath = (
            typeof process !== 'undefined' && typeof process.env !== 'undefined'
                ? process.env.PUBLIC_URL
                : '/'
        ).replace(/(.*)\/$/, '$1'),
        activePath = { ...rest }.path || 'error-404',
        rootHelpPath =
            appWrapper.props !== undefined &&
            appWrapper.props.portalType !== undefined &&
            appWrapper.props.portalType.toLowerCase() !== 'online'
                ? `${rootPath}/help/`
                : { ...rest }.helpUrl,
        appHomeUrl = `${
            { ...rest }.homeUrl !== undefined ? { ...rest }.homeUrl : window.location.hostname
        }${rootPath}`.replace(/^(.*)\/$/, '$1'),
        selfLayoutProps = {
            ...rest,
            activePath,
            helpPath: rootHelpPath,
            homeUrl: appHomeUrl,
            controller: appWrapper,
            landmarks,
            navBar,
            footer
        };

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <CalciteShell className={`page ${routeClassName} rb-flex rb-flex-col rb-h-screen`}>
                    {minimal ? (
                        <Component {...matchProps} {...selfLayoutProps} />
                    ) : (
                        <>
                            {navBar && (
                                <div className="navbar navbar-fixed-top" slot="header">
                                    <AppNavigationBar
                                        homeUrl={appHomeUrl}
                                        activePath={activePath}
                                        controller={appWrapper}
                                        helpPath={rootHelpPath}
                                    />
                                </div>
                            )}
                            {!landmarks ? (
                                <div className="app-main">
                                    <Component {...matchProps} {...rest} />
                                </div>
                            ) : (
                                <main role="main" className="rb-flex-grow rb-relative">
                                    <Component {...matchProps} {...rest} />
                                </main>
                            )}
                            {footer !== 'none' && (
                                <AppFooter homeUrl={appHomeUrl} content={footer} {...rest} />
                            )}
                        </>
                    )}
                </CalciteShell>
            )}
        />
    );
};

export default ReportBuilderApp;
