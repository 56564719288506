import AbstractWidget, { convertLengthToPixels } from './AbstractWidget';
import AbstractChartWidget, { createDashedLine, getBestTextForWidth, getPointStyle } from './AbstractChartWidget';
import { TextWidget } from './TextWidget';
import {
    adjustForLimits,
    createIndicatorRowTable,
    createIndicatorColumnTable,
    nullifyEmptyValues
} from './widgetDataUtils';
import { parseColor } from './widgetHelpers';
import { isNullOrWhitespace, hyphenate } from '../utils/object';
import { getNumberFormatter } from '../utils/localization';
import { DataManipulator } from 'data-catalog-js-api';
import ChartJS from '../lib/ChartJS';
import { Chart } from 'chart.js';

export default class LineChartWidget extends AbstractChartWidget {
    _layoutType = 'line'; // or 'radar' - which hard-wires some other options

    constructor(containerHtmlElement, design, specialType = 'line') {
        super(containerHtmlElement, design);
        this._layoutType = specialType;
    }

    // Render here is slightly special, because it can _detect_ if the calling (React) class has laid out some of the HTML before calling this method.
    render = (data, options = {}) => {
        if (
            this.container !== undefined &&
            this.container !== null &&
            this.design !== undefined &&
            this.design !== null
        ) {
            // Basics - this will give us the box or the chart content area... using super class
            const settings = {
                    ...LineChartWidget.getDefaults(),
                    ...this.design
                },
                chartContentElement = this.renderBox(data, {
                    element: 'figure',
                    boxClass: 'ia-chart-box',
                    showTitle: hyphenate(settings.titlePosition) !== 'inside-chart',
                    messagePadType: 'padding',
                    allowBackgroundFill: false,
                    settings: {
                        ...settings,
                        messageBackground: settings.backgroundColor // Transfer chart background to message as well
                    }
                }),
                doc = this.container.ownerDocument,
                topWidget = this.container.closest('.ia-report-widget'),
                { activeFeature, comparisonFeatures } = options,
                dataTable = DataManipulator.mergeTables(data, !settings.includeAllAreas), // Discard non-common features  unless they are comparisons
                dataAvailable =
                    dataTable !== undefined &&
                    dataTable !== null &&
                    dataTable.colIds !== undefined &&
                    dataTable.colIds.length > 0 &&
                    dataTable.rows !== undefined &&
                    dataTable.rows !== null,
                iAliases = this.indicatorAliases,
                aliasesProvided = iAliases !== undefined && iAliases !== null && iAliases.length > 0,
                palette =
                    settings.palette !== undefined && settings.palette !== null && settings.palette !== ''
                        ? AbstractChartWidget.getPaletteColors(settings.palette)
                        : [],
                compsPalette =
                    settings.comparisonsPalette !== undefined &&
                    settings.comparisonsPalette !== null &&
                    settings.comparisonsPalette !== ''
                        ? AbstractChartWidget.getPaletteColors(settings.comparisonsPalette)
                        : [],
                nfmt = getNumberFormatter(settings.locale, settings.numberFormat),
                canvas = this.createCanvas(settings, data, chartContentElement),
                chartOpts = {
                    ...this.getChartOptions(settings)
                };
            //console.log(settings); // DEBUG
            const {
                seriesBindingStyle,
                fillUnderLines = false,
                stackAreas = false,
                labelStyle,
                lineInterpolation = 'linear',
                steppedLineStyle
            } = settings;
            // Split data up - by indicator to start with...
            const allTable = DataManipulator.mergeTables(data.filter((d) => d.indicators !== undefined));
            AbstractWidget.filterTable(
                allTable,
                settings.hideMainFeature === true ? [] : null,
                settings.showComparisons === false
                    ? []
                    : !isNullOrWhitespace(settings.comparisonFeatureIds)
                    ? settings.comparisonFeatureIds.split(',').map((f) => f.split('|')[1])
                    : null
            );
            if (settings.aggregatedAreasAlias !== undefined && settings.aggregatedAreasAlias !== null)
                AbstractWidget.cleanAggregateNames(allTable, settings.aggregatedAreasAlias);
            // Set the value indices on the columns, to pick data up later...
            for (let i = 0; i < allTable.colIds.length; i++) allTable.colIds[i].index = i;
            // But if required, re-sort
            if (aliasesProvided) {
                AbstractWidget.applySortAndRename(allTable.colIds, iAliases, allTable.indicators, allTable.rows);
                allTable.headers = allTable.colIds.map((c) => c.label);
            }
            const limitsRequested =
                settings.showUpperAndLowerLimits === true &&
                settings.upperAndLowerLimitKeys !== undefined &&
                settings.upperAndLowerLimitKeys !== null;
            let fat = 0,
                cat = 0;
            let chartData = (
                hyphenate(seriesBindingStyle) === 'indicators-as-series'
                    ? DataManipulator.splitTable(allTable)
                    : [allTable]
            )
                .sort((da, db) => {
                    let c = 0;
                    if (da.feature !== undefined && db.feature !== undefined) {
                        c =
                            da.feature.comparison && !db.feature.comparison
                                ? 1
                                : !da.feature.comparison && db.feature.comparison
                                ? -1
                                : 0;
                    }
                    return c;
                })
                .map((d) => {
                    const fset = allTable.features.filter((f) => d.rowIds.indexOf(f.id) >= 0),
                        paletteSet = [],
                        dAlias = aliasesProvided ? iAliases.find((ia) => ia.id === d.indicators[0].id) : undefined,
                        dAliasIndex = aliasesProvided ? iAliases.findIndex((ia) => ia.id === d.indicators[0].id) : -1;
                    for (let fid of d.rowIds) {
                        if (fset.find((f) => f.id === fid).comparison && compsPalette.length > 0) {
                            paletteSet.push(
                                cat < compsPalette.length ? compsPalette[cat] : compsPalette[cat % compsPalette.length]
                            );
                            cat++;
                        } else {
                            paletteSet.push(fat < palette.length ? palette[fat] : palette[fat % palette.length]);
                            fat++;
                        }
                    }
                    return {
                        id: d.indicators[0].id,
                        name:
                            dAlias !== undefined
                                ? dAlias.label
                                : hyphenate(labelStyle) === 'short-name'
                                ? d.indicators[0].shortName
                                : d.indicators[0].name,
                        fullName: d.indicators[0].fullName || d.indicators[0].name,
                        data: d,
                        colors: paletteSet,
                        order: Math.max(dAliasIndex, 0)
                    };
                });
            let indicatorSeriesReally =
                hyphenate(seriesBindingStyle) === 'indicators-as-series' ||
                (hyphenate(seriesBindingStyle) === 'features-as-series' && chartData[0].data.rowIds.length === 1);
            // > 1 row then it must be 1 row per indicator for 1 feature?
            if (settings.seriesLabelFormat !== undefined) {
                let aind;
                for (let cd of chartData) {
                    //cd.name = cd.data.rows[0][0];
                    aind =
                        aliasesProvided && iAliases.find((ia) => ia.id === cd.data.indicators[0].id) !== undefined
                            ? iAliases.find((ia) => ia.id === cd.data.indicators[0].id).label
                            : cd.data.indicators[0].name;
                    let offset = 0;
                    for (let r of cd.data.rows) {
                        r[0] = settings.seriesLabelFormat
                            .replace(/#NAME/g, indicatorSeriesReally && chartData.length > 1 ? aind : r[0])
                            .replace(/#FNAME/g, r[0])
                            .replace(/#INAME/g, aind)
                            .replace(
                                /#INDEXALPHA|#INDEXA|#IDXA|#DATASETALPHA|#DATASETA/g,
                                'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(offset)
                            )
                            .replace(/#INDEX|#IDX|#DATASET/g, (offset + 1).toFixed(0));
                        offset++;
                    }
                }
            }
            let chartSubType = '',
                datasetOptions = {
                    cubicInterpolationMode: lineInterpolation === 'smooth-strict' ? 'monotone' : 'default'
                },
                multiValue = false;
            if (limitsRequested) {
                // Adjust the series/data according to the keys in the settings...
                const nSets = chartData.length;
                chartData = adjustForLimits(chartData, settings);
                const {
                    limitsLineColor = '#2c2c2c',
                    limitsLineWidth = '1px',
                    limitsEndLineHardWidth = '5px'
                } = settings;
                datasetOptions = {
                    ...datasetOptions,
                    errorBarColor: limitsLineColor,
                    errorBarWhiskerColor: limitsLineColor,
                    errorBarLineWidth: convertLengthToPixels(limitsLineWidth, false, true),
                    errorBarWhiskerSize:
                        typeof limitsEndLineWidth === 'number'
                            ? limitsEndLineHardWidth
                            : parseFloat(limitsEndLineHardWidth)
                };
                // If there are no limits then there will be no change in nSets, which means that nothing was adjusted...
                if (nSets !== chartData.length) {
                    chartSubType = 'WithErrorBars';
                    multiValue = true;
                }
            }
            if (hyphenate(seriesBindingStyle) === 'indicators-as-series') {
                chartData = createIndicatorRowTable(chartData); // Bodge for now - needs to be part of DataManipulator
            } else if (hyphenate(seriesBindingStyle) === 'features-as-series') {
                chartData = createIndicatorColumnTable(chartData);
                const tbl = chartData[0].data,
                    getInd = (id) => {
                        return tbl.indicators.find((i) => i.id === id);
                    };
                for (let i = 1; i < tbl.colIds.length; i++) {
                    const ii = getInd(tbl.colIds[i].iid);
                    tbl.headers[i] = settings.xAxisLabelFormat
                        .replace(/#IDATE/g, tbl.headers[i])
                        .replace(/#DATE/g, tbl.headers[i])
                        .replace(/#NAME/g, hyphenate(labelStyle) === 'short-name' ? ii.shortName : ii.name)
                        .replace(/#INAME/g, hyphenate(labelStyle) === 'short-name' ? ii.shortName : ii.name)
                        .replace(/^\[blank\]$/, '');
                }
            }
            if (settings.xAxisUseDate === true) {
                // Adjust the lines from simple Y values to x, y...
                for (let d of chartData) {
                    for (let i = 1; i < d.data.colIds.length; i++) {
                        const c = d.data.colIds[i];
                        if (c.date !== undefined && typeof c.date === 'number') {
                            for (let r of d.data.rows) {
                                r[i] = multiValue
                                    ? {
                                          ...r[i],
                                          x: new Date(c.date)
                                      }
                                    : {
                                          x: new Date(c.date),
                                          y: r[i]
                                      };
                            }
                        }
                    }
                }
            }
            // Line behaviour
            if (settings.animated) topWidget.setAttribute('class', `${topWidget.getAttribute('class')} ia-animated`);
            // chart.js 3 needs empty values not undefined - in-place
            nullifyEmptyValues(chartData, multiValue);
            const chartEngine = new ChartJS(canvas.getContext('2d')),
                chartTitleText = TextWidget.insertValuesIntoText(
                    settings.titleText,
                    data,
                    settings.numberFormat,
                    settings.locale,
                    '',
                    false
                ),
                dataPointLabels =
                    settings.labelDataPointsWithValue !== undefined &&
                    settings.labelDataPointsWithValue !== null &&
                    settings.labelDataPointsWithValue === true,
                chartPlugins = {
                    datalabels: {
                        enabled: dataPointLabels,
                        font: {
                            family: settings.axisFontFamily,
                            size: settings.axisFontSize
                        }
                    }
                };
            if (settings.paletteIsFixed === true) {
                for (let d of chartData) {
                    let c = d.colors;
                    if (c.length > 0) {
                        while (c.length < d.data.rows.length) {
                            c = c.concat([...c]);
                        }
                        d.colors = [...c];
                    }
                }
            }
            this.chart = chartEngine.render({
                name: 'timeseries',
                datasets: chartData,
                config: {
                    type:
                        this._layoutType !== 'line'
                            ? this._layoutType
                            : fillUnderLines && stackAreas
                            ? 'stackedArea'
                            : fillUnderLines
                            ? 'lineFilled'
                            : 'line',
                    beginAtZero: true,
                    fontFamily: settings.axisFontFamily,
                    numberFormatter: nfmt,
                    displayLegend: settings.showLegend,
                    legend: chartOpts.legend,
                    titles:
                        hyphenate(settings.titlePosition) === 'inside-chart' &&
                        chartTitleText !== null &&
                        chartTitleText !== ''
                            ? getBestTextForWidth(
                                  canvas.getContext('2d'),
                                  chartTitleText,
                                  convertLengthToPixels(settings.width, true, true) - 40,
                                  settings.axisFontFamily,
                                  settings.titleFontSize
                              ).lines
                            : [],
                    titleFontSize: parseInt(settings.titleFontSize),
                    titleFontWeight: settings.titleIsBold === true ? 'bold' : 'normal',
                    titleFontColor:
                        !isNullOrWhitespace(settings.titleColor) && hyphenate(settings.titleColor) !== 'not-set'
                            ? settings.titleColor
                            : undefined,
                    xAxisLabel: TextWidget.insertValuesIntoText(
                        settings.xAxisTitle,
                        data,
                        settings.numberFormat,
                        settings.locale,
                        false
                    ),
                    yAxisLabel: TextWidget.insertValuesIntoText(
                        settings.yAxisTitle,
                        data,
                        settings.numberFormat,
                        settings.locale,
                        false
                    ),
                    xAxisFontSize: parseInt(settings.axisFontSize),
                    yAxisFontSize: parseInt(settings.axisFontSize),
                    xAxisFontColor: parseColor(settings.axisFontColor || settings.xAxisColor || '#333'),
                    yAxisFontColor: parseColor(settings.axisFontColor || settings.yAxisColor || '#333'),
                    spanGaps: settings.spanGaps,
                    options: chartOpts,
                    lineTension: lineInterpolation === 'linear' ? 0.0 : 0.4,
                    stepped: lineInterpolation === 'stepped' ? steppedLineStyle : false,
                    maintainAspectRatio: false,
                    animationResizeDuration: 300,
                    autoDash: false, // handle the lines ourselves
                    datasetOptions: {
                        ...datasetOptions
                    },
                    plugins: chartPlugins
                },
                preRender: (chartConfig) => {
                    this.applyLineAnnotations(settings, chartConfig);
                    chartConfig.type = `${chartConfig.type}${chartSubType}`;
                    // Adjust order?
                    if (aliasesProvided) {
                        for (let cd of chartConfig.data.datasets) {
                            if (cd.indicator !== undefined) {
                                const cdi = iAliases.findIndex((i) => i.label === cd.indicator);
                                if (cdi >= 0) cd.order = cdi;
                            }
                        }
                    }
                    // Adjust the labels now the chart engine has had a 1st cut at layout
                    this.adjustAxisLabels(
                        {
                            ...settings,
                            axisLabelAllowWordWrap: settings.axisLabelAllowWordWrap && this._layoutType !== 'radar' // Unsupported in chart.js 3 - need to wait for a workaround
                        },
                        chartConfig,
                        canvas.getContext('2d')
                    );
                    if (this._layoutType === 'radar') {
                        // Apply/twist the axes colors to radial ones
                        const cloneAxis = {
                            angleLines: {
                                color: settings.yAxisColor || '#000000'
                            },
                            grid: {
                                ...chartConfig.options.scales.y.grid,
                                circular: settings.yAxisGridCircular
                            },
                            pointLabels: {
                                ...chartConfig.options.scales.y.ticks
                            },
                            ticks: {
                                ...chartConfig.options.scales.x.ticks
                            }
                        };
                        delete chartConfig.options.scales;
                        chartConfig.options.scales = { r: cloneAxis };
                        this.applyAxesSettings(
                            {
                                ...settings,
                                axisLabelAllowWordWrap: false, // Unsupported in chart.js 3 - need to wait for a workaround
                                yAxisShowGrid: true // Force the update
                            },
                            chartConfig,
                            false,
                            [
                                [], // No x really
                                chartConfig.options.scales.r
                            ]
                        );
                    } else {
                        this.applyAxesSettings(
                            {
                                ...settings
                            },
                            chartConfig
                        );
                    }
                    this.applyEvents(
                        {
                            settings,
                            axisLabelAllowWordWrap: settings.axisLabelAllowWordWrap && this._layoutType !== 'radar' // Unsupported in chart.js 3 - need to wait for a workaround
                        },
                        chartConfig,
                        options
                    );
                    // Line chart specific...and after all the series have been juggled about to match what the chart needs...
                    const {
                            featureLineMarkerSize,
                            featureLineMarkers,
                            featureLineStroke = 'solid',
                            featureLineWidth = '2px',
                            comparisonLineMarkerSize,
                            comparisonLineMarkers,
                            comparisonLineStroke = 'solid',
                            comparisonLineWidth = '2px'
                        } = settings,
                        pointSize = featureLineMarkerSize !== undefined ? parseInt(featureLineMarkerSize) : 6;
                    chartConfig.options.elements.point = {
                        ...chartConfig.options.elements.point,
                        radius: pointSize / 2.0,
                        pointStyle: featureLineMarkers !== undefined ? getPointStyle(featureLineMarkers) : 'circle'
                    };
                    chartConfig.options.elements.line = {
                        ...(chartConfig.options.elements.line !== undefined ? chartConfig.options.elements.line : {}),
                        borderWidth: featureLineWidth !== undefined ? parseInt(featureLineWidth) : 3,
                        borderDash: createDashedLine(
                            featureLineStroke,
                            featureLineWidth !== undefined ? parseInt(featureLineWidth) : 3
                        )
                    };
                    /* for (let ds of chartConfig.data.datasets) {
                        ds.borderWidth = featureLineWidth !== undefined ? parseInt(featureLineWidth) : 3;
                    }*/
                    if (chartConfig.options.plugins.legend !== undefined) {
                        if (chartConfig.options.plugins.legend.labels === undefined)
                            chartConfig.options.plugins.legend.labels = {};
                        chartConfig.options.plugins.legend.labels.usePointStyle = true;
                        chartConfig.options.plugins.legend.labels.fillStyle = 'rgba(255, 255, 255, 0)'; //true;
                    }
                    //chartConfig.options.plugins.legend.labels.boxWidth = pointSize;
                    const coreSets = chartConfig.data.datasets.filter(
                        (d) => d.feature !== undefined && d.feature.comparison !== true
                    );
                    let coff = 0;
                    if (featureLineMarkers !== undefined && featureLineMarkers === 'auto') {
                        for (let cd of coreSets) {
                            cd.pointStyle = getPointStyle(featureLineMarkers, coff);
                            coff++;
                        }
                    }
                    // Line behaviour, after lines have been split into series
                    if (
                        comparisonLineStroke !== featureLineStroke ||
                        comparisonLineWidth !== featureLineWidth ||
                        comparisonLineMarkerSize !== undefined ||
                        comparisonLineMarkers !== undefined
                    ) {
                        const comparisonSets = chartConfig.data.datasets.filter(
                                (d) => d.feature !== undefined && d.feature.comparison === true
                            ),
                            cdw =
                                comparisonLineWidth !== undefined
                                    ? parseInt(comparisonLineWidth)
                                    : featureLineWidth !== undefined
                                    ? parseInt(featureLineWidth)
                                    : 3,
                            cdd = createDashedLine(comparisonLineStroke, cdw),
                            cpr =
                                (comparisonLineMarkerSize !== undefined
                                    ? parseInt(comparisonLineMarkerSize)
                                    : pointSize) / 2.0,
                            cps =
                                comparisonLineMarkers !== undefined
                                    ? comparisonLineMarkers
                                    : featureLineMarkers !== undefined
                                    ? featureLineMarkers
                                    : 'circle';
                        coff = coreSets.length;
                        for (let cd of comparisonSets) {
                            cd.borderWidth = cdw;
                            cd.borderDash = cdd;
                            cd.pointStyle = getPointStyle(cps, coff);
                            cd.pointRadius = cpr;
                            coff++;
                        }
                    }
                    const { customLineStrokes, customLineWidths } = settings;
                    if (customLineWidths !== undefined && customLineWidths !== null) {
                        const tokens = customLineWidths.toLowerCase().split(',');
                        for (let i = 0; i < Math.min(chartConfig.data.datasets.length, tokens.length); i++)
                            chartConfig.data.datasets[i].borderWidth = parseFloat(tokens[i]);
                    }
                    if (customLineStrokes !== undefined && customLineStrokes !== null) {
                        const tokens = customLineStrokes.toLowerCase().split(',');
                        for (let i = 0; i < Math.min(chartConfig.data.datasets.length, tokens.length); i++)
                            chartConfig.data.datasets[i].borderDash = createDashedLine(
                                tokens[i],
                                chartConfig.data.datasets[i].borderWidth
                            );
                    }
                    // Line colors...
                    if (palette.length > 0) {
                        //&& (seriesBindingStyle === 'FeaturesAsSeries') && indicatorSeriesReally)
                        let i = 0;
                        while (i < palette.length && i < chartConfig.data.datasets.length) {
                            chartConfig.data.datasets[i].borderColor = palette[i];
                            chartConfig.data.datasets[i].backgroundColor = fillUnderLines ? palette[i] : undefined; //palette.slice(i); ? Fill
                            i++;
                        }
                    }
                    if (compsPalette.length > 0) {
                        // && (seriesBindingStyle === 'FeaturesAsSeries') && indicatorSeriesReally)
                        const comparisonSets = chartConfig.data.datasets.filter(
                            (d) => d.feature !== undefined && d.feature.comparison === true
                        );
                        let i = 0;
                        while (i < compsPalette.length && i < comparisonSets.length) {
                            comparisonSets[i].borderColor = compsPalette[i];
                            comparisonSets[i].backgroundColor = fillUnderLines ? compsPalette[i] : undefined; //palette.slice(i); ? Fill
                            i++;
                        }
                    }
                    if (
                        chartConfig.options.plugins.legend !== undefined &&
                        (settings.legendLineStyle === undefined || settings.legendLineStyle !== 'point')
                    ) {
                        if (chartConfig.options.plugins.legend.labels === undefined)
                            chartConfig.options.plugins.legend.labels = {};
                        chartConfig.options.plugins.legend.labels.generateLabels = (chart) => {
                            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart),
                                defDash = createDashedLine(
                                    featureLineStroke,
                                    featureLineWidth !== undefined ? parseInt(featureLineWidth) : 3
                                );
                            for (let key in labels) {
                                const lds = chart.data.datasets[labels[key].datasetIndex];
                                labels[key].pointStyle = createLineImage(
                                    topWidget.ownerDocument,
                                    lds.borderDash !== undefined ? lds.borderDash : defDash,
                                    lds.borderWidth !== undefined
                                        ? lds.borderWidth
                                        : featureLineWidth !== undefined
                                        ? parseInt(featureLineWidth)
                                        : 3,
                                    lds.borderColor !== undefined ? lds.borderColor : labels[key].strokeStyle,
                                    { width: 40, height: 14 },
                                    settings.legendLineStyle || 'line'
                                );
                            }
                            return labels;
                        };
                    }
                    for (let ds of chartConfig.data.datasets) {
                        ds.pointBackgroundColor = ds.borderColor;
                    }
                    if (dataPointLabels) {
                        this.applyDataPointLabels(settings, chartConfig);
                    }
                },
                onComplete: (chartInstance) => {
                    //console.log(ce); // DEBUG
                    //this.forceAxesRender(chartInstance.chart, settings);
                    //if (!animBound && settings.animated)
                    //{
                    //    const widgetId = topWidget.getAttribute('id');
                    //    window[`widgetChart${widgetId}`] = chartEngine.chart;
                    //    AbstractChartWidget.bindAnimationUpdate(topWidget);
                    //}
                }
            });
            this.applyChartActions(settings, chartData);
            topWidget.setAttribute('class', topWidget.getAttribute('class').replace(/\s(placeholder)/g, ''));
        }
    };

    static getDefaults = () => {
        return {
            ...AbstractChartWidget.getDefaults(),
            ...AbstractChartWidget.plotAreaDefaults,
            ...AbstractChartWidget.statisticsLineDefaults,
            ...AbstractChartWidget.annotationLineDefaults,
            spanGaps: false, // New in RB 2.0, default is therefore "off",
            seriesLabelFormat: '#NAME',
            lineInterpolation: 'linear',
            steppedLineStyle: 'none',
            fillUnderLines: false,
            stackAreas: false,
            xAxisUseMargins: true, // RB1.0 had these by default, so keep them here...
            seriesBindingStyle: 'indicators-as-series',
            labelDataPointsWithValue: false,
            labelDataPointsFormat: '#VALY',
            labelDataPointsRotation: 0,
            labelDataPointsAlign: 'top',
            labelDataPointsOffset: '0px',
            tooltipFormat: '#VALX: #VALY'
        };
    };
}

export class RadarChartWidget extends LineChartWidget {
    constructor(container, design) {
        super(container, design, 'radar');
    }

    static getDefaults = () => {
        return {
            ...LineChartWidget.getDefaults(),
            seriesBindingStyle: 'features-as-series',
            xAxisLabelFormat: '#INAME',
            yAxisColor: '#666666',
            yAxisGridColor: '#bbbbbb',
            yAxisRange: '0,auto',
            axisLabelAllowWordWrap: false // Unsupported in chart.js 3 - need to wait for a workaround
        };
    };
}

export const createLineImage = (
    doc,
    lineDashPattern = [],
    lineWidth = 2,
    color = '#ff0000',
    imageSizeInPixels = { width: 16, height: 16 },
    lineShape = 'zigzag'
) => {
    const canvas = doc.createElement('canvas');
    canvas.width = imageSizeInPixels.width;
    canvas.height = imageSizeInPixels.height;
    canvas.setAttribute(
        'style',
        `position: absolute; left: -1000px; top: -1000px; width: ${imageSizeInPixels.width}px; height: ${imageSizeInPixels.height}px;`
    );
    doc.documentElement.appendChild(canvas);
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = lineWidth;
    ctx.setLineDash(lineDashPattern);
    ctx.strokeStyle = color;
    if (lineShape === 'zig-zag') {
        ctx.moveTo(8, imageSizeInPixels.height - 2);
        ctx.lineTo(8 + Math.round((imageSizeInPixels.width - 16) / 2.0), 2);
        ctx.lineTo(imageSizeInPixels.width - 8, imageSizeInPixels.height - 2);
        ctx.stroke();
    } else {
        ctx.moveTo(8, imageSizeInPixels.height / 2.0);
        ctx.lineTo(imageSizeInPixels.width - 8, imageSizeInPixels.height / 2.0);
        ctx.stroke();
    }
    const img = new Image(imageSizeInPixels.width, imageSizeInPixels.height);
    img.src = canvas.toDataURL();
    canvas.remove();
    return img;
};
